import React from 'react'

function Privacy() {
    return (
        <div className='text-start container-xl px-4 px-xl-0 bodyCounterPadding'>
            <h2>Datenschutzerkl&auml;rung</h2>
            <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
            </p><br />
            <p>Swissma GmbH<br />Samir Menasria<br />Fällmisstrasse 12<br />8832 Wilen b. Wollerau</p>
            <p>Telefon: 0789115730<br />E-Mail: info@swissma.ch<br />WebSite: http://finanu.ch/ </p>
            <p>&nbsp;</p>
            <h2>Allgemeiner Hinweis</h2>
            <p>Gest&uuml;tzt auf Artikel 13 der schweizerischen Bundesverfassung und den datenschutzrechtlichen Bestimmungen des
                Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsph&auml;re sowie auf
                Schutz vor Missbrauch ihrer pers&ouml;nlichen Daten. Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der
                gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.</p>
            <p>In Zusammenarbeit mit unseren Hosting-Providern bem&uuml;hen wir uns, die Datenbanken so gut wie m&ouml;glich vor
                fremden Zugriffen, Verlusten, Missbrauch oder vor F&auml;lschung zu sch&uuml;tzen.</p>
            <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                m&ouml;glich.</p>
            <p>Durch die Nutzung dieser Website erkl&auml;ren Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten
                gem&auml;ss der nachfolgenden Beschreibung einverstanden. Diese Website kann grunds&auml;tzlich ohne Registrierung
                besucht werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und
                Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person
                bezogen werden. Personenbezogene Daten, insbesondere Name, Adresse oder E-Mail-Adresse werden soweit m&ouml;glich
                auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.</p>
            <p>&nbsp;</p>
            <h2>Bearbeitung von Personendaten</h2>
            <p>Personendaten sind alle Angaben, die sich auf eine bestimmte oder bestimmbare Person beziehen. Eine betroffene Person
                ist eine Person, &uuml;ber die Personendaten bearbeitet werden. Bearbeiten umfasst jeden Umgang mit Personendaten,
                unabh&auml;ngig von den angewandten Mitteln und Verfahren, insbesondere das Aufbewahren, Bekanntgeben, Beschaffen,
                L&ouml;schen, Speichern, Ver&auml;ndern, Vernichten und Verwenden von Personendaten.</p>
            <p>Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht. Im &Uuml;brigen bearbeiten wir –
                soweit und sofern die EU-DSGVO anwendbar ist – Personendaten gem&auml;ss folgenden Rechtsgrundlagen im Zusammenhang
                mit Art. 6 Abs. 1 DSGVO:</p>
            <ul>
                <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> - Die betroffene Person hat ihre Einwilligung
                    in die Verarbeitung der sie betreffenden personenbezogenen Daten f&uuml;r einen spezifischen Zweck oder mehrere
                    bestimmte Zwecke gegeben.</li>
                <li><strong>Vertragserf&uuml;llung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)</strong> - Die
                    Verarbeitung ist f&uuml;r die Erf&uuml;llung eines Vertrags, dessen Vertragspartei die betroffene Person ist,
                    oder zur Durchf&uuml;hrung vorvertraglicher Massnahmen erforderlich, die auf Anfrage der betroffenen Person
                    erfolgen.</li>
                <li><strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong> - Die Verarbeitung ist zur
                    Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt.</li>
                <li><strong>Schutz lebenswichtiger Interessen (Art. 6 Abs. 1 S. 1 lit. d. DSGVO)</strong> - Die Verarbeitung ist
                    erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer anderen nat&uuml;rlichen Person zu
                    sch&uuml;tzen.</li>
                <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung ist zur Wahrung
                    der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen
                    oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern,
                    &uuml;berwiegen.</li>
                <li><strong>Bewerbungsverfahren als vorvertragliches bzw. vertragliches Verh&auml;ltnis (Art. 9 Abs. 2 lit. b
                    DSGVO)</strong> - Soweit im Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten
                    im Sinne des Art. 9 Abs. 1 DSGVO (z.B. Gesundheitsdaten, wie Schwerbehinderteneigenschaft oder ethnische
                    Herkunft) bei Bewerbern angefragt werden, damit der Verantwortliche oder die betroffene Person die ihm bzw. ihr
                    aus dem Arbeitsrecht und dem Recht der sozialen Sicherheit und des Sozialschutzes erwachsenden Rechte
                    aus&uuml;ben und seinen bzw. ihren diesbez&uuml;glichen Pflichten nachkommen kann, erfolgt deren Verarbeitung
                    nach Art. 9 Abs. 2 lit. b. DSGVO, im Fall des Schutzes lebenswichtiger Interessen der Bewerber oder anderer
                    Personen gem. Art. 9 Abs. 2 lit. c. DSGVO oder f&uuml;r Zwecke der Gesundheitsvorsorge oder der Arbeitsmedizin,
                    f&uuml;r die Beurteilung der Arbeitsf&auml;higkeit des Besch&auml;ftigten, f&uuml;r die medizinische Diagnostik,
                    die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder f&uuml;r die Verwaltung von Systemen und
                    Diensten im Gesundheits- oder Sozialbereich gem. Art. 9 Abs. 2 lit. h. DSGVO. Im Fall einer auf freiwilliger
                    Einwilligung beruhenden Mitteilung von besonderen Kategorien von Daten, erfolgt deren Verarbeitung auf Grundlage
                    von Art. 9 Abs. 2 lit. a. DSGVO.</li>
            </ul>
            <p>Wir bearbeiten Personendaten f&uuml;r jene Dauer, die f&uuml;r den jeweiligen Zweck oder die jeweiligen Zwecke
                erforderlich ist. Bei l&auml;nger dauernden Aufbewahrungspflichten aufgrund von gesetzlichen und sonstigen
                Pflichten, denen wir unterliegen, schr&auml;nken wir die Bearbeitung entsprechend ein.</p>
            <p>&nbsp;</p>
            <h2>Massgebliche Rechtsgrundlagen</h2>
            <p>Nach Massgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die
                Rechtsgrundlage in der Datenschutzerkl&auml;rung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage f&uuml;r
                die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage f&uuml;r die
                Verarbeitung zur Erf&uuml;llung unserer Leistungen und Durchf&uuml;hrung vertraglicher Massnahmen sowie Beantwortung
                von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage f&uuml;r die Verarbeitung zur Erf&uuml;llung
                unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage f&uuml;r die
                Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. F&uuml;r den Fall, dass
                lebenswichtige Interessen der betroffenen Person oder einer anderen nat&uuml;rlichen Person eine Verarbeitung
                personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.</p>
            <p>&nbsp;</p>
            <h2>Sicherheitsmassnahmen</h2>
            <p>Wir treffen nach Massgabe der gesetzlichen Vorgaben unter Ber&uuml;cksichtigung des Stands der Technik, der
                Implementierungskosten und der Art, des Umfangs, der Umst&auml;nde und der Zwecke der Verarbeitung sowie der
                unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmasses der Bedrohung der Rechte und Freiheiten
                nat&uuml;rlicher Personen geeignete technische und organisatorische Massnahmen, um ein dem Risiko angemessenes
                Schutzniveau zu gew&auml;hrleisten.</p>
            <p>Zu den Massnahmen geh&ouml;ren insbesondere die Sicherung der Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit
                von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden
                Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verf&uuml;gbarkeit und ihrer Trennung. Des Weiteren haben
                wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die L&ouml;schung von Daten und Reaktionen
                auf die Gef&auml;hrdung der Daten gew&auml;hrleisten. Ferner ber&uuml;cksichtigen wir den Schutz personenbezogener
                Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>
            <p>&nbsp;</p>
            <h2>&Uuml;bermittlung von personenbezogenen Daten</h2>
            <p>Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen,
                Unternehmen, rechtlich selbstst&auml;ndige Organisationseinheiten oder Personen &uuml;bermittelt oder sie ihnen
                gegen&uuml;ber offengelegt werden. Zu den Empf&auml;ngern dieser Daten k&ouml;nnen z.B. mit IT-Aufgaben beauftragte
                Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden, geh&ouml;ren. In
                solchen Fall beachten wir die gesetzlichen Vorgaben und schliessen insbesondere entsprechende Vertr&auml;ge bzw.
                Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empf&auml;ngern Ihrer Daten ab.</p>
            <p>&nbsp;</p>
            <h2>Datenverarbeitung in Drittl&auml;ndern</h2>
            <p>Sofern wir Daten in einem Drittland (d.h., ausserhalb der Europ&auml;ischen Union (EU), des Europ&auml;ischen
                Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder
                der Offenlegung bzw. &Uuml;bermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt
                dies nur im Einklang mit den gesetzlichen Vorgaben.</p>
            <p>Vorbehaltlich ausdr&uuml;cklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher &Uuml;bermittlung,
                verarbeiten wir die Daten nur in Drittl&auml;ndern mit einem anerkannten Datenschutzniveau, vertraglicher
                Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, beim Vorliegen von Zertifizierungen oder
                verbindlichen internen Datenschutzvorschriften (Art. 44 bis 49 DSGVO, Informationsseite der EU-Kommission:
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de).</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Cookies</h2>
            <p>Diese Website verwendet Cookies. Cookies sind Textdateien, die Daten von besuchten Websites oder Domains enthalten
                und von einem Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient in erster Linie dazu, die
                Informationen &uuml;ber einen Benutzer w&auml;hrend oder nach seinem Besuch innerhalb eines Onlineangebotes zu
                speichern. Zu den gespeicherten Angaben k&ouml;nnen z.B. die Spracheinstellungen auf einer Webseite, der
                Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde, geh&ouml;ren. Zu dem Begriff der
                Cookies z&auml;hlen wir ferner andere Technologien, die die gleichen Funktionen wie Cookies erf&uuml;llen (z.B. wenn
                Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs" bezeichnet)
            </p>
            <p>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</p>
            <ul>
                <li><strong>Tempor&auml;re Cookies (auch: Session- oder Sitzungs-Cookies)</strong>: Tempor&auml;re Cookies werden
                    sp&auml;testens gel&ouml;scht, nachdem ein Nutzer ein Online-Angebot verlassen und seinen Browser geschlossen
                    hat.</li>
                <li><strong>Permanente Cookies</strong>: Permanente Cookies bleiben auch nach dem Schliessen des Browsers
                    gespeichert. So kann beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt
                    werden, wenn der Nutzer eine Website erneut besucht. Ebenso k&ouml;nnen die Interessen von Nutzern, die zur
                    Reichweitenmessung oder zu Marketingzwecken verwendet werden, in einem solchen Cookie gespeichert werden.</li>
                <li><strong>First-Party-Cookies</strong>: First-Party-Cookies werden von uns selbst gesetzt.</li>
                <li><strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>: Drittanbieter-Cookies werden
                    haupts&auml;chlich von Werbetreibenden (sog. Dritten) verwendet, um Benutzerinformationen zu verarbeiten.</li>
                <li><strong>Notwendige (auch: essenzielle oder unbedingt erforderliche) Cookies</strong>: Cookies k&ouml;nnen zum
                    einen f&uuml;r den Betrieb einer Webseite unbedingt erforderlich sein (z.B. um Logins oder andere Nutzereingaben
                    zu speichern oder aus Gr&uuml;nden der Sicherheit).</li>
                <li><strong>Statistik-, Marketing- und Personalisierung-Cookies</strong>: Ferner werden Cookies im Regelfall auch im
                    Rahmen der Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines Nutzers oder sein Verhalten (z.B.
                    Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem Nutzerprofil
                    gespeichert werden. Solche Profile dienen dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren potenziellen
                    Interessen entsprechen. Dieses Verfahren wird auch als "Tracking", d.h., Nachverfolgung der potenziellen
                    Interessen der Nutzer bezeichnet. Soweit wir Cookies oder "Tracking"-Technologien einsetzen, informieren wir Sie
                    gesondert in unserer Datenschutzerkl&auml;rung oder im Rahmen der Einholung einer Einwilligung.</li>
            </ul>
            <p>Hinweise zu Rechtsgrundlagen: Auf welcher Rechtsgrundlage wir Ihre personenbezogenen Daten mithilfe von Cookies
                verarbeiten, h&auml;ngt davon ab, ob wir Sie um eine Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung
                von Cookies einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erkl&auml;rte Einwilligung.
                Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B.
                an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn
                der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erf&uuml;llen.</p>
            <p>Speicherdauer: Sofern wir Ihnen keine expliziten Angaben zur Speicherdauer von permanenten Cookies mitteilen (z. B.
                im Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die Speicherdauer bis zu zwei Jahre betragen
                kann.</p>
            <p>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): Abh&auml;ngig davon, ob die Verarbeitung auf Grundlage
                einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die M&ouml;glichkeit, eine erteilte
                Einwilligung zu widerrufen oder der Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen
                (zusammenfassend als "Opt-Out" bezeichnet). Sie k&ouml;nnen Ihren Widerspruch zun&auml;chst mittels der
                Einstellungen Ihres Browsers erkl&auml;ren, z.B., indem Sie die Nutzung von Cookies deaktivieren (wobei hierdurch
                auch die Funktionsf&auml;higkeit unseres Onlineangebotes eingeschr&auml;nkt werden kann). Ein Widerspruch gegen den
                Einsatz von Cookies zu Zwecken des Onlinemarketings kann auch mittels einer Vielzahl von Diensten, vor allem im Fall
                des Trackings, &uuml;ber die Webseiten https://optout.aboutads.info und https://www.youronlinechoices.com/
                erkl&auml;rt werden. Daneben k&ouml;nnen Sie weitere Widerspruchshinweise im Rahmen der Angaben zu den eingesetzten
                Dienstleistern und Cookies erhalten.</p>
            <p>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: Wir setzen ein Verfahren zum
                Cookie-Einwilligungs-Management ein, in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies, bzw.
                der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen und Anbieter eingeholt sowie
                von den Nutzern verwaltet und widerrufen werden k&ouml;nnen. Hierbei wird die Einwilligungserkl&auml;rung
                gespeichert, um deren Abfrage nicht erneut wiederholen zu m&uuml;ssen und die Einwilligung entsprechend der
                gesetzlichen Verpflichtung nachweisen zu k&ouml;nnen. Die Speicherung kann serverseitig und/oder in einem Cookie
                (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer,
                bzw. dessen Ger&auml;t zuordnen zu k&ouml;nnen. Vorbehaltlich individueller Angaben zu den Anbietern von
                Cookie-Management-Diensten gelten die folgenden Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu
                zwei Jahren betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt der
                Einwilligung, Angaben zur Reichweite der Einwilligung (z. B. welche Kategorien von Cookies und/oder Diensteanbieter)
                sowie dem Browser, System und verwendeten Endger&auml;t gespeichert.</p>
            <ul>
                <li>Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
                    Meta-/Kommunikationsdaten (z.B. Ger&auml;te-Informationen, IP-Adressen).</li>
                <li>Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                <li>Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                    lit. f. DSGVO).</li>
            </ul>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r SSL-/TLS-Verschl&uuml;sselung</h2>
            <p>Diese Website nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie
                zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-/TLS-Verschl&uuml;sselung. Eine
                verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &quot;http://&quot; auf
                &quot;https://&quot; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
            <p>Wenn die SSL bzw. TLS Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln,
                nicht von Dritten mitgelesen werden.</p>
            <p>&nbsp;</p>
            <h2>Daten&uuml;bertragungssicherheit (ohne SSL)</h2>
            <p>Bitte beachten Sie, dass Daten, die &uuml;ber ein offenes Netz wie das Internet oder einen E-Mail-Dienst ohne
                SSL-Verschl&uuml;sselung &uuml;bermittelt werden, f&uuml;r jedermann einsehbar sind. Eine unverschl&uuml;sselte
                Verbindung erkennen Sie daran, dass die Adresszeile des Browsers &quot;http://&quot; anzeigt und kein Schloss-Symbol
                in Ihrer Browserzeile angezeigt wird. Informationen die &uuml;ber das Internet &uuml;bertragen werden und online
                empfangene Inhalte, k&ouml;nnen unter Umst&auml;nden &uuml;ber Netze von Drittanbietern &uuml;bermittelt werden. Wir
                k&ouml;nnen die Vertraulichkeit von Mitteilungen oder Unterlagen, die &uuml;ber solche offenen Netze oder Netze von
                Drittanbietern &uuml;bermittelt werden, nicht garantieren.</p>
            <p>Wenn Sie &uuml;ber ein offenes Netz oder Netze von Drittanbietern personenbezogene Informationen bekannt geben,
                sollten Sie sich der Tatsache bewusst sein, dass Ihre Daten verloren gehen oder Dritte potenziell auf diese
                Informationen zugreifen und folglich die Daten ohne Ihre Zustimmung sammeln und nutzen k&ouml;nnen. Zwar werden in
                vielen F&auml;llen die einzelnen Datenpakete verschl&uuml;sselt &uuml;bermittelt, nicht aber die Namen des Absenders
                und des Empf&auml;ngers. Selbst wenn der Absender und der Empf&auml;nger im gleichen Land wohnen, erfolgt die
                Daten&uuml;bermittlung &uuml;ber solche Netze h&auml;ufig und ohne Kontrollen auch &uuml;ber Drittstaaten, d.h. auch
                &uuml;ber L&auml;nder, die nicht das gleiche Datenschutzniveau bieten wie Ihr Domizilland. Wir &uuml;bernehmen
                f&uuml;r die Sicherheit Ihrer Daten w&auml;hrend der &Uuml;bermittlung &uuml;ber das Internet keine Verantwortung
                und lehnen jede Haftung f&uuml;r mittelbare und unmittelbare Verluste ab. Wir bitten Sie, andere
                Kommunikationsmittel zu benutzen, sollten Sie dies aus Gr&uuml;nden der Sicherheit f&uuml;r notwendig oder
                vern&uuml;nftig erachten.</p>
            <p>Trotz umfangreicher technischer und organisatorischer Sicherungsvorkehrungen, k&ouml;nnen m&ouml;glicherweise Daten
                verloren gehen oder von Unbefugten abgefangen und/oder manipuliert werden. Wir treffen soweit m&ouml;glich geeignete
                technische und organisatorische Sicherheitsmassnahmen, um dies innerhalb unseres Systems zu verhindern. Ihr Computer
                befindet sich indessen ausserhalb des von uns kontrollierbaren Sicherheitsbereichs. Es obliegt Ihnen als Benutzer,
                sich &uuml;ber die erforderlichen Sicherheitsvorkehrungen zu informieren und diesbez&uuml;glich geeignete Massnahmen
                zu treffen. Als Website-Betreiber haften wir keinesfalls f&uuml;r Sch&auml;den, die Ihnen aus Datenverlust oder
                -manipulation entstehen k&ouml;nnen.</p>
            <p>Daten welche Sie in Online-Formularen angeben, k&ouml;nnen zwecks Auftragsabwicklung an beauftragte Dritte
                weitergegeben und von diesen eingesehen und allenfalls bearbeitet werden.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Server-Log-Files</h2>
            <p>Der Provider dieser Website erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr
                Browser automatisch an uns &uuml;bermittelt. Dies sind:</p>
            <ul>
                <li>Browsertyp und Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
            </ul>
            <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenf&uuml;hrung dieser Daten mit anderen
                Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachtr&auml;glich zupr&uuml;fen, wenn uns
                konkrete Anhaltspunkte f&uuml;r eine rechtswidrige Nutzung bekannt werden.</p>
            <p>&nbsp;</p>
            <h2>Dienste von Dritten</h2>
            <p>Diese Website verwenden allenfalls Google Maps f&uuml;r das Einbetten von Karten, Google Invisible reCAPTCHA f&uuml;r
                den Schutz gegen Bots und Spam sowie YouTube f&uuml;r das Einbetten von Videos.</p>
            <p>Diese Dienste der amerikanischen Google LLC verwenden unter anderem Cookies und infolgedessen werden Daten an Google
                in den USA &uuml;bertragen, wobei wir davon ausgehen, dass in diesem Rahmen kein personenbezogenes Tracking allein
                durch die Nutzung unserer Website stattfindet.</p>
            <p>Google hat sich verpflichtet, einen angemessenen Datenschutz gem&auml;ss dem amerikanisch-europ&auml;ischen und dem
                amerikanisch-schweizerischen Privacy Shield zu gew&auml;hrleisten.</p>
            <p>Weitere Informationen finden sich in der <a href='https://policies.google.com/privacy?hl=de' target="_blank" rel="noreferrer"
                >Datenschutzerkl&auml;rung von Google</a>.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Kontaktformular</h2>
            <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der
                von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von Anschlussfragen bei
                uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Newsletterdaten</h2>
            <p>Wenn Sie den auf dieser Website angebotenen Newsletter beziehen m&ouml;chten, ben&ouml;tigen wir von Ihnen eine
                E-Mail-Adresse sowie Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass Sie der Inhaber der
                angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht
                erhoben. Diese Daten verwenden wir ausschliesslich f&uuml;r den Versand der angeforderten Informationen und geben
                sie nicht an Dritte weiter.</p>
            <p>Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
                Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa &uuml;ber den &laquo;Austragen-Link&raquo; im Newsletter.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Kommentarfunktion auf dieser Website</h2>
            <p>F&uuml;r die Kommentarfunktion auf dieser Website werden neben Ihrem Kommentar auch Angaben zum Zeitpunkt der
                Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gew&auml;hlte
                Nutzername gespeichert.</p>
            <p><i>Speicherung der IP Adresse</i></p>
            <p>Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die Kommentare verfassen. Da wir Kommentare auf
                unserer Seite nicht vor der Freischaltung pr&uuml;fen, ben&ouml;tigen wir diese Daten, um im Falle von
                Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser vorgehen zu k&ouml;nnen.</p>
            <p><i>Abonnieren von Kommentaren</i></p>
            <p>Als Nutzer der Seite k&ouml;nnen Sie nach einer Anmeldung Kommentare abonnieren. Sie erhalten eine
                Best&auml;tigungsemail, um zu pr&uuml;fen, ob Sie der Inhaber der angegebenen E-Mail-Adresse sind. Sie k&ouml;nnen
                diese Funktion jederzeit &uuml;ber einen Link in den Info-Mails abbestellen.</p>
            <p>&nbsp;</p>
            <h2>Rechte betroffener Personen</h2>
            <p><strong>Recht auf Best&auml;tigung</strong></p>
            <p>Jede betroffene Person hat das Recht, vom Betreiber der Website eine Best&auml;tigung dar&uuml;ber zu verlangen, ob
                betroffene Personen betreffende, personenbezogene Daten verarbeitet werden. M&ouml;chten Sie dieses
                Best&auml;tigungsrecht in Anspruch nehmen, k&ouml;nnen Sie sich hierzu jederzeit an den Datenschutzbeauftragten
                wenden.</p> <br />
            <p><strong>Recht auf Auskunft</strong></p>
            <p>Jede von der Verarbeitung betroffene Person mit personenbezogenen Daten hat das Recht, jederzeit vom Betreiber dieser
                Website unentgeltliche Auskunft &uuml;ber die zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie
                dieser Auskunft zu erhalten. Ferner kann gegebenenfalls &uuml;ber folgende Informationen Auskunft gegeben werden:
            </p>
            <ul>
                <li>die Verarbeitungszwecke</li>
                <li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
                <li>die Empf&auml;nger, gegen&uuml;ber denen die personenbezogenen Daten offengelegt worden sind oder noch
                    offengelegt werden</li>
                <li>falls m&ouml;glich, die geplante Dauer, f&uuml;r die die personenbezogenen Daten gespeichert werden, oder, falls
                    dies nicht m&ouml;glich ist, die Kriterien f&uuml;r die Festlegung dieser Dauer</li>
                <li>das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung der sie betreffenden personenbezogenen Daten oder
                    auf Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese
                    Verarbeitung</li>
                <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbeh&ouml;rde</li>
                <li>wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verf&uuml;gbaren
                    Informationen &uuml;ber die Herkunft der Daten</li>
            </ul>
            <p>Ferner steht der betroffenen Person ein Auskunftsrecht dar&uuml;ber zu, ob personenbezogene Daten an ein Drittland
                oder an eine internationale Organisation &uuml;bermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen
                Person im &uuml;brigen das Recht zu, Auskunft &uuml;ber die geeigneten Garantien im Zusammenhang mit der
                &uuml;bermittlung zu erhalten.</p>
            <p>M&ouml;chten Sie dieses Auskunftsrecht in Anspruch nehmen, k&ouml;nnen Sie sich hierzu jederzeit an unseren
                Datenschutzbeauftragten wenden.</p> <br />
            <p><strong>Recht auf Berichtigung</strong></p>
            <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die unverz&uuml;gliche
                Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht der betroffenen Person
                das Recht zu, unter Ber&uuml;cksichtigung der Zwecke der Verarbeitung, die Vervollst&auml;ndigung
                unvollst&auml;ndiger personenbezogener Daten - auch mittels einer erg&auml;nzenden Erkl&auml;rung - zu verlangen.
            </p>
            <p>M&ouml;chten Sie dieses Berichtigungsrecht in Anspruch nehmen, k&ouml;nnen Sie sich hierzu jederzeit an unseren
                Datenschutzbeauftragten wenden.</p> <br />
            <p><strong>Recht auf L&ouml;schung (Recht auf Vergessen werden)</strong></p>
            <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem Verantwortlichen dieser
                Website zu verlangen, dass die sie betreffenden personenbezogenen Daten unverz&uuml;glich gel&ouml;scht werden,
                sofern einer der folgenden Gr&uuml;nde zutrifft und soweit die Verarbeitung nicht erforderlich ist:</p>
            <ul>
                <li>Die personenbezogenen Daten wurden f&uuml;r solche Zwecke erhoben oder auf sonstige Weise verarbeitet, f&uuml;r
                    welche sie nicht mehr notwendig sind</li>
                <li>Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung st&uuml;tzte, und es fehlt an
                    einer anderweitigen Rechtsgrundlage f&uuml;r die Verarbeitung</li>
                <li>Die betroffene Person legt aus Gr&uuml;nden, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen
                    die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r die Verarbeitung vor,
                    oder die betroffene Person legt im Falle von Direktwerbung und damit verbundenem Profiling Widerspruch gegen die
                    Verarbeitung ein</li>
                <li>Die personenbezogenen Daten wurden unrechtm&auml;ssig verarbeitet</li>
                <li>Die L&ouml;schung der personenbezogenen Daten ist zur Erf&uuml;llung einer rechtlichen Verpflichtung nach dem
                    Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt</li>
                <li>Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft, die einem Kind
                    direkt gemacht wurden, erhoben</li>
            </ul>
            <p>Sofern einer der oben genannten Gr&uuml;nde zutrifft und Sie die L&ouml;schung von personenbezogenen Daten, die
                beimBetreiber dieser Website gespeichert sind, veranlassen m&ouml;chten, k&ouml;nnen Sie sich hierzu jederzeit an
                unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte dieser Website wird veranlassen, dass dem
                L&ouml;schverlangen unverz&uuml;glich nachgekommen wird.</p> <br />
            <p><strong>Recht auf Einschr&auml;nkung der Verarbeitung</strong></p>
            <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem Verantwortlichen dieser
                Website die Einschr&auml;nkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
            </p>
            <ul>
                <li>Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar f&uuml;r eine
                    Dauer, die es dem Verantwortlichen erm&ouml;glicht, die Richtigkeit der personenbezogenen Daten zu
                    &uuml;berpr&uuml;fen</li>
                <li>Die Verarbeitung ist unrechtm&auml;ssig, die betroffene Person lehnt die L&ouml;schung der personenbezogenen
                    Daten ab und verlangt stattdessen die Einschr&auml;nkung der Nutzung der personenbezogenen Daten</li>
                <li>Der Verantwortliche ben&ouml;tigt die personenbezogenen Daten f&uuml;r die Zwecke der Verarbeitung nicht
                    l&auml;nger, die betroffene Person ben&ouml;tigt sie jedoch zur Geltendmachung, Aus&uuml;bung oder Verteidigung
                    von Rechtsanspr&uuml;chen</li>
                <li>Die betroffene Person hat aus Gr&uuml;nden, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen
                    die Verarbeitung eingelegt und es steht noch nicht fest, ob die berechtigten Gr&uuml;nde des Verantwortlichen
                    gegen&uuml;ber denen der betroffenen Person &uuml;berwiegen</li>
            </ul>
            <p>Sofern eine der oben genannten Voraussetzungen gegeben ist Sie die Einschr&auml;nkung von personenbezogenen Daten,
                die beim Betreiber dieser Website gespeichert sind, verlangen m&ouml;chten, k&ouml;nnen Sie sich hierzu jederzeit an
                unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte dieser Website wird die Einschr&auml;nkung der
                Verarbeitung veranlassen.</p> <br />
            <p><strong>Recht auf Daten&uuml;bertragbarkeit</strong></p>
            <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die sie betreffenden
                personenbezogenen Daten in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten. Sie hat
                ausserdem das Recht, dass diese Daten bei Vorliegen der gesetzlichen Voraussetzungen einem anderen Verantwortlichen
                &uuml;bermittelt werden.</p>
            <p>Ferner hat die betroffene Person das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem
                Verantwortlichen an einen anderen Verantwortlichen &uuml;bermittelt werden, soweit dies technisch machbar ist und
                sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeintr&auml;chtigt werden.</p>
            <p>Zur Geltendmachung des Rechts auf Daten&uuml;bertragbarkeit k&ouml;nnen Sie sich jederzeit an den vom Betreiber
                dieser Website bestellten Datenschutzbeauftragten wenden.</p> <br />
            <p><strong>Recht auf Widerspruch</strong></p>
            <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, aus Gr&uuml;nden, die sich aus
                ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten,
                Widerspruch einzulegen.</p>
            <p>Der Betreiber dieser Website verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei
                denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die den
                Interessen, Rechten und Freiheiten der betroffenen Person &uuml;berwiegen, oder wenn die Verarbeitung der
                Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient.</p>
            <p>Zur Aus&uuml;bung des Rechts auf Widerspruch k&ouml;nnen Sie sich direkt an den Datenschutzbeauftragten dieser
                Website wenden.</p> <br />
            <p><strong>Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</strong></p>
            <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, eine abgegebene Einwilligung zur
                Verarbeitung personenbezogener Daten jederzeit zu widerrufen.</p>
            <p>M&ouml;chten Sie Ihr Recht auf Widerruf einer Einwilligung geltend machen, k&ouml;nnen Sie sich hierzu jederzeit an
                unseren Datenschutzbeauftragten wenden.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Widerspruch Werbe-Mails</h2>
            <p>Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht
                ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
                Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von
                Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
            <p>&nbsp;</p>
            <h2>Kostenpflichtige Leistungen</h2>
            <p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zus&auml;tzliche Daten erfragt, wie z.B. Zahlungsangaben,
                um Ihre Bestellung resp. Ihren Auftrag ausf&uuml;hren zu k&ouml;nnen. Wir speichern diese Daten in unseren Systemen,
                bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.</p>
            <p>&nbsp;</p>
            <h2>Verwendung von Google Maps</h2>
            <p>Diese Website nutzt das Angebot von Google Maps. Dadurch können wir Ihnen interaktive Karten direkt in der Website
                anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion. Durch den Besuch auf der Website erhält
                Google die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Dies erfolgt
                unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto
                besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die
                Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Google
                speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder
                bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht
                eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über
                Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser
                Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen. Weitere Informationen zu Zweck und
                Umfang der Datenerhebung und ihrer Verarbeitung durch Google erhalten Sie neben weiteren Informationen zu Ihren
                diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre unter: <a
                    href="http://www.google.de/intl/de/policies/privacy" target="_blank" rel="noreferrer"
                    >www.google.de/intl/de/policies/privacy</a>.</p>
            <p>&nbsp;</p>
            <h2>Google Ads</h2>
            <p>Diese Website nutzt das Google Conversion-Tracking. Sind Sie &uuml;ber eine von Google geschaltete Anzeige auf unsere
                WebSite gelangt, wird von Google Ads ein Cookie auf Ihrem Rechner gesetzt. Das Cookie f&uuml;r Conversion-Tracking
                wird gesetzt, wenn ein Nutzer auf eine von Google geschaltete Anzeige klickt. Diese Cookies verlieren nach 30 Tagen
                ihre G&uuml;ltigkeit und dienen nicht der pers&ouml;nlichen Identifizierung. Besucht der Nutzer bestimmte Seiten
                unserer Website und das Cookie ist noch nicht abgelaufen, k&ouml;nnen wir und Google erkennen, dass der Nutzer auf
                die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder Google Ads-Kunde erh&auml;lt ein anderes
                Cookie. Cookies k&ouml;nnen somit nicht &uuml;ber die Websites von Ads-Kunden nachverfolgt werden. Die mithilfe des
                Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken f&uuml;r Ads-Kunden zu erstellen,
                die sich f&uuml;r Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf
                ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden.
                Sie erhalten jedoch keine Informationen, mit denen sich Nutzer pers&ouml;nlich identifizieren lassen.</p>
            <p>M&ouml;chten Sie nicht am Tracking teilnehmen, k&ouml;nnen Sie das hierf&uuml;r erforderliche Setzen eines Cookies
                ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert oder Ihren
                Browser so einstellen, dass Cookies von der Domain «googleleadservices.com» blockiert werden.</p>
            <p>Bitte beachten Sie, dass Sie die Opt-out-Cookies nicht l&ouml;schen d&uuml;rfen, solange Sie keine Aufzeichnung von
                Messdaten w&uuml;nschen. Haben Sie alle Ihre Cookies im Browser gel&ouml;scht, m&uuml;ssen Sie das jeweilige Opt-out
                Cookie erneut setzen.</p>
            <p>&nbsp;</p>
            <h2>Einsatz von Google Remarketing</h2>
            <p>Diese Website verwendet die Remarketing-Funktion der Google Inc. Die Funktion dient dazu, Websitenbesuchern innerhalb
                des Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu pr&auml;sentieren. Im Browser des Websitenbesuchers
                wird ein sog. „Cookie“ gespeichert, der es erm&ouml;glicht, den Besucher wiederzuerkennen, wenn dieser Websiten
                aufruft, die dem Werbenetzwerk von Google angeh&ouml;ren. Auf diesen Seiten k&ouml;nnen dem Besucher Werbeanzeigen
                pr&auml;sentiert werden, die sich auf Inhalte beziehen, die der Besucher zuvor auf Websiten aufgerufen hat, die die
                Remarketing Funktion von Google verwenden.</p>
            <p>Nach eigenen Angaben erhebt Google bei diesem Vorgang keine personenbezogenen Daten. Sollten Sie die Funktion
                Remarketing von Google dennoch nicht w&uuml;nschen, k&ouml;nnen Sie diese grunds&auml;tzlich deaktivieren, indem Sie
                die entsprechenden Einstellungen unter <a href="http://www.google.com/settings/ads" target="_blank" rel="noreferrer"
                    >http://www.google.com/settings/ads</a> vornehmen. Alternativ k&ouml;nnen Sie den Einsatz von
                Cookies f&uuml;r interessenbezogene Werbung &uuml;ber die Werbenetzwerkinitiative deaktivieren, indem Sie den
                Anweisungen unter <a href="http://www.networkadvertising.org/managing/opt_out.asp" target="_blank" rel="noreferrer"
                    >http://www.networkadvertising.org/managing/opt_out.asp</a> folgen.</p>
            <p>&nbsp;</p>
            <h2>Verwendung von Google reCAPTCHA</h2>
            <p>Wir nutzen &laquo;Google reCAPTCHA&raquo; (im Folgenden &laquo;reCAPTCHA&raquo;) auf unseren Websites. Anbieter ist
                die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend &laquo;Google&raquo;. Mit
                reCAPTCHA soll &uuml;berpr&uuml;ft werden, ob die Dateneingabe auf unseren Websites (z. B. in einem Kontaktformular)
                durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des
                Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die
                Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z. B. IP-Adresse, Verweildauer des
                Websitebesuchers auf der Website oder vom Nutzer get&auml;tigte Mausbewegungen). Die bei der Analyse erfassten Daten
                werden an Google weitergeleitet. Die reCAPTCHA-Analysen laufen vollst&auml;ndig im Hintergrund. Websitebesucher
                werden nicht darauf hingewiesen, dass eine Analyse stattfindet.</p>
            <p>Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse daran, seine Webangebote vor missbr&auml;uchlicher automatisierter Aussp&auml;hung und vor SPAM zu
                sch&uuml;tzen. Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerkl&auml;rung von Google entnehmen
                Sie folgenden Links: https://www.google.com/intl/de/policies/privacy/ und https://policies.google.com/terms?hl=de.
            </p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Google Analytics</h2>
            <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited. Wenn der Verantwortliche
                für die Datenverarbeitung auf dieser Website ausserhalb des Europäischen Wirtschaftsraumes oder der Schweiz sitzt,
                dann erfolgt die Google Analytics Datenverarbeitung durch Google LLC. Google LLC und Google Ireland Limited werden
                nachfolgend &laquo;Google&raquo; genannt.</p>
            <p>Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter
                ausgestalten. Diese Website verwendet Google Analytics zudem für eine geräteübergreifende Analyse von
                Besucherströmen, die über eine User-ID durchgeführt wird. Sofern Sie über ein Google-Benutzerkonto verfügen, können
                Sie in den dortigen Einstellungen unter &laquo;Meine Daten&raquo;, &laquo;persönliche Daten&raquo; die
                geräteübergreifende Analyse Ihrer Nutzung deaktivieren.</p>
            <p>Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Die im Rahmen von Google
                Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Wir
                weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code &laquo;_anonymizeIp();&raquo; erweitert
                wurde, um eine anonymisierte Erfassung von IP-Adressen zu gewährleisten. Dadurch werden IP-Adressen gekürzt
                weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten
                ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend
                gelöscht.</p>
            <p>Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im
                Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website
                auszuwerten, um Reports über die Websitenaktivitäten zusammenzustellen und um weitere mit der Websitennutzung und
                der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitenbetreiber zu erbringen.</p>
            <p>Google Analytics verwendet Cookies. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website
                werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Sie können die
                Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
                jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
                werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung
                der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
                verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a
                    href="https://www.swissanwalt.ch/de/gaoptout.aspx" target="_blank" rel="noreferrer" >Google Analytics
                    deaktivieren</a>.</p>
            <p>Ausserdem können Sie die Nutzung von Google Analytics auch verhindern, indem sie auf diesen Link klicken: <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer" >Google Analytics
                deaktivieren</a>. Hierdurch wird ein sog. opt-out Cookie auf ihrem Datenträger gespeichert, der die Verarbeitung
                personenbezogener Daten durch Google Analytics verhindert. Bitte beachten Sie, dass bei einem Löschen sämtlicher
                Cookies auf Ihrem Endgerät auch diese Opt-out-Cookies gelöscht werden, d.h., dass Sie erneut die Opt-out-Cookies
                setzen müssen, wenn Sie weiterhin diese Form der Datenerhebung verhindern wollen. Die Opt-out-Cookies sind pro
                Browser und Rechner/Endgerät gesetzt und müssen daher für jeden Browser, Rechner oder anderes Endgerät gesondert
                aktiviert werden.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Google AdSense</h2>
            <p>Wir verwenden auf dieser Website Google AdSense. Das ist ein Anzeigenprogramm der Firma Google Inc. In Europa ist das
                Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
                verantwortlich. Mit Google AdSense können wir auf dieser Webseite Werbeanzeigen einblenden, die zu unserem Thema
                passen.</p>
            <p>Google AdSense setzt Cookies ein, um für die Nutzer relevante Anzeigen zu schalten, die Berichte zur
                Kampagnenleistung zu verbessern oder um zu vermeiden, dass ein Nutzer die gleichen Anzeigen mehrmals sieht. Über
                eine Cookie-ID erfasst Google, welche Anzeigen in welchem Browser geschaltet werden und kann so verhindern, dass
                diese mehrfach angezeigt werden. Darüber hinaus kann Google AdSense mithilfe von Cookie-IDs sog. Conversions
                erfassen, die Bezug zu Anzeigenanfragen haben. Das ist etwa der Fall, wenn ein Nutzer eine Google Ads-Anzeige sieht
                und später mit demselben Browser die Website des Werbetreibenden aufruft und dort etwas kauft. Laut Google enthalten
                Google Ads-Cookies keine personenbezogenen Informationen.</p>
            <p>Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von
                Google auf. Durch die Einbindung von Google Ads erhält Google die Information, dass Sie den entsprechenden Teil
                unseres Internetauftritts aufgerufen oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von
                Google registriert sind, kann Google den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Google registriert
                sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass Google Ihre IP-Adresse in Erfahrung bringt und
                speichert.</p>
            <p>Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise verhindern:</p>
            <ol type="a">
                <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von
                    Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten;</li>
                <li>durch Deaktivierung der Cookies für Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass Cookies von
                    der Domain &laquo;www.googleadservices.com&raquo; blockiert werden, <a href="https://adssettings.google.com"
                        target="_blank" rel="noreferrer" >https://adssettings.google.com</a>, wobei diese Einstellung gelöscht wird,
                    wenn Sie Ihre Cookies löschen;</li>
                <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne
                    &laquo;About Ads&raquo; sind, über den Link <a href="http://www.aboutads.info/choices" target="_blank" rel="noreferrer"
                        >https://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre
                    Cookies löschen;</li>
                <li>durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link <a
                    href="http://www.google.com/settings/ads/plugin" target="_blank" rel="noreferrer"
                    >https://www.google.com/settings/ads/plugin</a>. Wir weisen Sie darauf hin, dass Sie in diesem
                    Fall gegebenenfalls nicht alle Funktionen dieses Angebots vollumfänglich nutzen können.</li>
            </ol>
            <p>Rechtsgrundlage für die Verarbeitung Ihrer Daten ist eine Interessenabwägung, wonach der vorstehend geschilderten
                Verarbeitung Ihrer personenbezogenen Daten keine überwiegenden gegenteiligen Interessen Ihrerseits entgegenstehen
                (Art. 6 Abs. 1 S. 1 lit. f DSGVO). Weitere Informationen zu Google Ads von Google erhalten Sie unter <a
                    href="https://ads.google.com/intl/de_DE/home/" target="_blank" rel="noreferrer"
                    >https://ads.google.com/intl/de_DE/home/</a>, sowie zum Datenschutz bei Google allgemein: <a
                        href="https://www.google.de/intl/de/policies/privacy" target="_blank" rel="noreferrer"
                        >https://www.google.de/intl/de/policies/privacy</a>. Alternativ können Sie die Website der Network
                Advertising Initiative (NAI) unter <a href="http://www.networkadvertising.org" target="_blank" rel="noreferrer"
                    >https://www.networkadvertising.org</a> besuchen.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung für die Nutzung von Google Web Fonts</h2>
            <p>Diese Website nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
                bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren
                Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird
                eine Standardschrift von Ihrem Computer genutzt.</p>
            <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq"
                target="_blank" rel="noreferrer" >https://developers.google.com/fonts/faq</a> und in der
                Datenschutzerkl&auml;rung von Google: <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer"
                    >https://www.google.com/policies/privacy/</a></p>
            <p>&nbsp;</p>
            <h2>Google Tag Manager</h2>
            <p>Google Tag Manager ist eine L&ouml;sung, mit der wir sog. Website-Tags &uuml;ber eine Oberfl&auml;che verwalten
                k&ouml;nnen und so z.B. Google Analytics sowie andere Google-Marketing-Dienste in unser Onlineangebot einbinden
                können. Der Tag Manager selbst, welcher die Tags implementiert, verarbeitet keine personenbezogenen Daten der
                Nutzer. Im Hinblick auf die Verarbeitung der personenbezogenen Daten der Nutzer wird auf die folgenden Angaben zu
                den Google-Diensten verwiesen. Nutzungsrichtlinien: <a
                    href="https://www.google.com/intl/de/tagmanager/use-policy.html" target="_blank" rel="noreferrer"
                    >https://www.google.com/intl/de/tagmanager/use-policy.html</a>.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Hubspot</h2>
            <p>Unsere Website nutzt Hubspot, eine Marketing-Automatisierungs-Software der Firma HubSpot, 2nd Floor 30 North Wall
                Quay, Dublin 1, Ireland. HubSpot ist ein Software-Unternehmen aus den USA mit einer Europ&auml;ischen Niederlassung
                in Irland. Hubspot unterst&uuml;tzt uns dabei, die Nutzung unseres Portals zu analysieren. Dazu setzt Hubspot
                Cookies ein.</p>
            <p>Bestimmte Nutzungsdaten werden dabei mit Ihrer Person verkn&uuml;pft (bspw. nach Eintragung in einem
                Registrierungsformular) und in unserem CRM gespeichert. So k&ouml;nnen wir Ihnen speziell auf Ihr Interesse
                zugeschnittene Informationen und Angebote zukommen lassen.</p>
            <p>Dabei werden Ihre personenbezogenen Daten wom&ouml;glich auch auf Servern von Hubspot in die Vereinigten Staaten
                (USA) weitergeleitet. Das angemessene Schutzniveau wird dabei dadurch hergestellt, dass HubSpot, Inc. sich am
                EU-US-Datenschutzschild-Abkommen beteiligt und f&uuml;r dessen Einhaltung zertifiziert ist.</p>
            <p>Wir setzen Hubspot ein, um Ihnen bedarfsgerechte Informationen und Angebote zu unterbreiten. Demnach haben wir ein
                berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f) Datenschutzgrundverordnung an dieser Verarbeitung.
                Rechtsgrundlage f&uuml;r die Verarbeitung Ihrer personenbezogenen Daten durch uns im Zusammenhang mit der Verwendung
                von Hubspot ist Art. 6 Abs. 1 lit. f) Datenschutzgrundverordnung.</p>
            <p>Im Rahmen der Nutzung von Hubspot speichern wir Ihre personenbezogenen Daten so lange, wie es n&ouml;tig ist, um
                Ihnen bedarfsgerechte Informationen und Angebote zukommen zu lassen.</p>
            <p>Die Bereitstellung der &uuml;ber Hubspot erhobenen personenbezogenen Daten ist nicht gesetzlich oder vertraglich
                vorgeschrieben oder f&uuml;r einen Vertragsabschluss erforderlich. Wenn Sie uns diese Daten nicht zur Verf&uuml;gung
                stellen, k&ouml;nnen wir Ihnen keine bedarfsgerechten Informationen und Angebote unterbreiten.</p>
            <p>N&auml;here Informationen zur Datennutzung durch Hubspot finden Sie in der Datenschutzerkl&auml;rung von Hubspot
                unter: <a href="https://legal.hubspot.com/de/privacy-policy" target="_blank" rel="noreferrer"
                    >https://legal.hubspot.com/de/privacy-policy</a>.</p>
            <p>Der Nutzung Ihrer Daten k&ouml;nnen Sie jederzeit widersprechen, z.B. per E-Mail an unsere E-Mail-Adresse in dieser
                Datenschutzerkl&auml;rung.</p>
            <p>HubSpot ist unter den Bedingungen des <a href="https://www.privacyshield.gov/welcome" target="_blank" rel="noreferrer"
                >&laquo;EU-U.S. Privacy Shield Frameworks&raquo;</a> zertifiziert und unterliegt dem TRUSTe's
                Privacy Seal sowie dem &laquo;U.S.-Swiss Safe Harbor&raquo; Framework.</p>
            <ul>
                <li><a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="noreferrer" >Mehr Informationen zu den
                    Datenschutzbestimmungen von HubSpot</a></li>
                <li><a href="https://legal.hubspot.com/de/datenschutz" target="_blank" rel="noreferrer" >Mehr Informationen von HubSpot
                    hinsichtlich der EU-Datenschutzbestimmungen</a></li>
                <li><a href="https://knowledge.hubspot.com/articles/kcs_article/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser"
                    target="_blank" rel="noreferrer" >Mehr Informationen von HubSpot im Browser eines Besuchers gesetzte
                    Cookies</a></li>
                <li><a href="https://knowledge.hubspot.com/articles/kcs_article/account/hubspot-cookie-security-and-privacy"
                    target="_blank" rel="noreferrer" >Mehr Informationen zu den auf den HubSpot-Websites gesetzte Cookies</a></li>
            </ul>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r die Nutzung von Hotjar</h2>
            <p>Diese Website benutzt den Service von Hotjar zur Verbesserung der Benutzerfreundlichkeit.&nbsp;Die Hotjar Ltd. Hotjar
                Ltd. ist ein europ&auml;isches Unternehmen mit Sitz in Malta (Hotjar Ltd, Level 2, St Julians Business Centre, 3,
                Elia Zammit Street, St Julians STJ 1000, Malta, Europe). Es k&ouml;nnen Mausklicks sowie Maus- und Scroll-Bewegungen
                aufgezeichnet werden. Ebenso k&ouml;nnen auf dieser Internetseite durchgef&uuml;hrte Tastatureingaben aufgezeichnet
                werden. Personifizierte Informationen werden dabei nicht aufgenommen. Hotjar verwendet zur Erhebung und
                &Uuml;bertragung Ihrer Daten einen Tracking-Code. Sobald Sie unsere Website besuchen, erhebt der Hotjar
                Tracking-Code automatisch die auf Ihrer Aktivit&auml;t beruhenden Daten und speichert Sie auf den Hotjar Servern
                (Standort Irland) ab. Zudem erheben die durch die Website auf Ihrem Computer oder Ihrem Endendger&auml;t platzierten
                Cookies ebenfalls Daten. F&uuml;r weitere Informationen, wie Hotjar arbeitet, besuchen Sie diese Seite:&nbsp;<a
                    href="https://www.hotjar.com/privacy" target="_blank" rel="noreferrer" >https://www.hotjar.com/privacy</a>.</p>
            <p>M&ouml;chten Sie der Erhebung der Daten durch Hotjar widersprechen (Opt-Out), klicken Sie bitte hier:&nbsp;<a
                href="https://www.hotjar.com/opt-out" target="_blank" rel="noreferrer" >https://www.hotjar.com/opt-out</a>.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Facebook</h2>
            <p>Diese Website verwendet Funktionen von Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA . Bei Aufruf
                unserer Seiten mit Facebook-Plug-Ins wird eine Verbindung zwischen Ihrem Browser und den Servern von Facebook
                aufgebaut. Dabei werden bereits Daten an Facebook &uuml;bertragen. Besitzen Sie einen Facebook-Account, k&ouml;nnen
                diese Daten damit verkn&uuml;pft werden. Wenn Sie keine Zuordnung dieser Daten zu Ihrem Facebook-Account
                w&uuml;nschen, loggen Sie sich bitte vor dem Besuch unserer Seite bei Facebook aus. Interaktionen, insbesondere das
                Nutzen einer Kommentarfunktion oder das Anklicken eines „Like“- oder „Teilen“-Buttons werden ebenfalls an Facebook
                weitergegeben. Mehr erfahren Sie unter <a href="https://de-de.facebook.com/about/privacy" target="_blank" rel="noreferrer"
                    >https://de-de.facebook.com/about/privacy</a>.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Twitter</h2>
            <p>Diese Website verwendet Funktionen von Twitter, Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, USA. Bei
                Aufruf unserer Seiten mit Twitter-Plug-Ins wird eine Verbindung zwischen Ihrem Browser und den Servern von Twitter
                aufgebaut. Dabei werden bereits Daten an Twitter &uuml;bertragen. Besitzen Sie einen Twitter-Account, k&ouml;nnen
                diese Daten damit verkn&uuml;pft werden. Wenn Sie keine Zuordnung dieser Daten zu Ihrem Twitter-Account wünschen,
                loggen Sie sich bitte vor dem Besuch unserer Seite bei Twitter aus. Interaktionen, insbesondere das Anklicken eines
                „Re-Tweet“-Buttons werden ebenfalls an Twitter weitergegeben. Mehr erfahren Sie unter <a
                    href="https://twitter.com/privacy" target="_blank" rel="noreferrer" >https://twitter.com/privacy</a>.</p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Instagram</h2>
            <p>Auf unserer Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die
                Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA integriert. Wenn Sie in Ihrem Instagram-Account
                eingeloggt sind k&ouml;nnen Sie durch Anklicken des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem
                Instagram-Profil verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir
                weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie
                deren Nutzung durch Instagram erhalten.</p>
            <p>Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von Instagram: <a
                href="https://instagram.com/about/legal/privacy/" target="_blank" rel="noreferrer"
                >http://instagram.com/about/legal/privacy/</a></p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r LinkedIn</h2>
            <p>Wir setzen innerhalb unseres Onlineangebotes die Marketing-Dienste des sozialen Netzwerks LinkedIn der LinkedIn
                Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Ireland (&laquo;LinkedIn&raquo;) ein.</p>
            <p>Diese verwenden Cookies, also Textdateien, die auf Ihrem Computer gespeichert werden. Dies erm&ouml;glicht uns eine
                Analyse der Benutzung der Internetseite durch Sie. So k&ouml;nnen wir beispielsweise den Erfolg unserer Anzeigen
                messen und Nutzern Produkte anzeigen, f&uuml;r die sie sich zuvor interessiert haben.</p>
            <p>Erfasst werden dadurch z.B. Informationen zum Betriebssystem, zum Browser, die von Ihnen zuvor aufgerufene
                Internetseite (Referrer-URL), welche Webseiten der Nutzer aufgesucht, welche Angebote der Nutzer angeklickt hat, und
                Datum und Uhrzeit Ihres Besuchs auf unserer Internetseite.</p>
            <p>Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Internetseite werden pseudonymisiert an
                einen Server von LinkedIn in den USA &uuml;bertragen und dort gespeichert. LinkedIn speichert also nicht den Namen
                oder die E-Mailadresse des jeweiligen Nutzers. Die oben genannten Daten werden vielmehr nur demjenigen zugeordnet,
                bei dem das Cookie erzeugt wurde. Dies gilt nicht, sofern der Nutzer LinkedIn eine Verarbeitung ohne
                Pseudonymisierung erlaubt hat oder ein LinkedIn-Konto hat.</p>
            <p>Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
                wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall m&ouml;glicherweise nicht s&auml;mtliche Funktionen dieser
                Internetseite vollumf&auml;nglich werden nutzen k&ouml;nnen. Der Nutzung Ihrer Daten k&ouml;nnen sie auch direkt bei
                LinkedIn widersprechen: <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                    target="_blank" rel="noreferrer" >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.</p>
            <p>Wir nutzen LinkedIn Analytics, um die Nutzung unserer Website analysieren und regelm&auml;ssig verbessern zu
                k&ouml;nnen. &Uuml;ber die gewonnenen Statistiken k&ouml;nnen wir unser Angebot verbessern und f&uuml;r Sie als
                Nutzer interessanter ausgestalten. Alle LinkedIn Unternehmen haben die Standardvertragsklauseln angenommen, um zu
                gew&auml;hrleisten, dass der f&uuml;r die Entwicklung, Durchf&uuml;hrung und Erhaltung der Dienste notwendige
                Datenverkehr in die USA und Singapur auf rechtm&auml;ssige Weise stattfindet. Sofern wir die Nutzer um eine
                Einwilligung bitten, ist die Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. a DSGVO. Ansonsten ist
                Rechtsgrundlage f&uuml;r die Nutzung von LinkedIn Analytics Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
            <p>Informationen des Drittanbieters: LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2 Ireland; <a
                href="https://www.linkedin.com/legal/user-agreement?_l=de_DE" target="_blank" rel="noreferrer"
                >Nutzervereinbarung</a> und <a href="https://www.linkedin.com/legal/privacy-policy?_l=de_DE"
                    target="_blank" rel="noreferrer" >Datenschutzerkl&auml;rung</a>.</p>
            <p>&nbsp;</p>
            <h2>Externe Zahlungsdienstleister</h2>
            <p>Diese Website setzt externe Zahlungsdienstleister ein, &uuml;ber deren Plattformen die Nutzer und wir
                Zahlungstransaktionen vornehmen k&ouml;nnen. Zum Beispiel &uuml;ber</p>
            <ul>
                <li>PostFinance (https://www.postfinance.ch/de/detail/rechtliches-barrierefreiheit.html)</li>
                <li>Visa (https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html)</li>
                <li>Mastercard (https://www.mastercard.ch/de-ch/datenschutz.html)</li>
                <li>American Express (https://www.americanexpress.com/de/legal/online-datenschutzerklarung.html)</li>
                <li>Paypal (https://www.paypal.com/de/webapps/mpp/ua/privacy-full)</li>
                <li>Bexio AG (https://www.bexio.com/de-CH/datenschutz)</li>
                <li>Payrexx AG (https://www.payrexx.ch/site/assets/files/2592/datenschutzerklaerung.pdf)</li>
                <li>Apple Pay (https://support.apple.com/de-ch/ht203027)</li>
                <li>Stripe (https://stripe.com/ch/privacy)</li>
                <li>Klarna (https://www.klarna.com/de/datenschutz/)</li>
                <li>Skrill (https://www.skrill.com/de/fusszeile/datenschutzrichtlinie/)</li>
                <li>Giropay (https://www.giropay.de/rechtliches/datenschutzerklaerung) etc.</li>
            </ul>
            <p>Im Rahmen der Erf&uuml;llung von Vertr&auml;gen setzen wir die Zahlungsdienstleister auf Grundlage der
                schweizerischen Datenschutzverordnung sowie und soweit n&ouml;tig, des Art. 6 Abs. 1 lit. b. EU-DSGVO ein. Im
                &Uuml;brigen setzen wir externe Zahlungsdienstleister auf Grundlage unserer berechtigten Interessen gem.
                schweizerischer Datenschutzverordnung sowie und soweit n&ouml;tig, gem. Art. 6 Abs. 1 lit. f. EU-DSGVO ein, um
                unseren Nutzern effektive und sichere Zahlungsm&ouml;glichkeit zu bieten.</p>
            <p>Zu den durch die Zahlungsdienstleister verarbeiteten Daten geh&ouml;ren Bestandsdaten, wie z.B. der Name und die
                Adresse, Bankdaten, wie u.a. Kontonummern oder Kreditkartennummern, Passw&ouml;rter, TANs und Pr&uuml;fsummen sowie
                die Vertrags-, Summen und empf&auml;ngerbezogenen Angaben. Die Angaben sind erforderlich, um die Transaktionen
                durchzuf&uuml;hren. Die eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei
                diesen gespeichert. Wir als Betreiber erhalten keinerlei Informationen zu (Bank-) Konto oder Kreditkarte, sondern
                lediglich Informationen zur Best&auml;tigung (Annahme) oder Ablehnung der Zahlung. Unter Umst&auml;nden werden die
                Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien &uuml;bermittelt. Diese &Uuml;bermittlung
                bezweckt die Identit&auml;ts- und Bonit&auml;tspr&uuml;fung. Hierzu verweisen wir auf die AGB und
                Datenschutzhinweise der Zahlungsdienstleister.</p>
            <p>F&uuml;r die Zahlungsgesch&auml;fte gelten die Gesch&auml;ftsbedingungen und die Datenschutzhinweise der jeweiligen
                Zahlungsdienstleister, welche innerhalb der jeweiligen Website, bzw. Transaktionsapplikationen abrufbar sind. Wir
                verweisen auf diese ebenfalls zwecks weiterer Informationen und Geltendmachung von Widerrufs-, Auskunfts- und
                anderen Betroffenenrechten.</p>
            <p>&nbsp;</p>
            <h2>Newsletter - Mailchimp</h2>
            <p>Der Versand der Newsletter erfolgt mittels des Versanddienstleisters 'MailChimp', einer Newsletterversandplattform
                des US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. Die
                Datenschutzbestimmungen des Versanddienstleisters k&ouml;nnen Sie <a href="https://mailchimp.com/legal/privacy/"
                    target="_blank" rel="noreferrer" >hier</a> einsehen. The Rocket Science Group LLC d/b/a MailChimp ist unter dem
                Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europ&auml;isches Datenschutzniveau
                einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active&t=1"
                    target="_blank" rel="noreferrer" >PrivacyShield</a>). Der Versanddienstleister wird auf Grundlage unserer
                berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO und eines Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3
                S. 1 DSGVO eingesetzt.</p>
            <p>Der Versanddienstleister kann die Daten der Empf&auml;nger in pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer,
                zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung des Versandes und
                der Darstellung der Newsletter oder f&uuml;r statistische Zwecke verwenden. Der Versanddienstleister nutzt die Daten
                unserer Newsletterempf&auml;nger jedoch nicht, um diese selbst anzuschreiben oder um die Daten an Dritte
                weiterzugeben.</p>
            <p>&nbsp;</p>
            <h2>Newsletter per WhatsApp</h2>
            <p>Unseren kostenlosen Newsletter k&ouml;nnen Sie auch &uuml;ber den Instant-Messaging-Dienst WhatsApp beziehen.
                WhatsApp ist ein Dienst der WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland,
                einem Tochterunternehmen der WhatsApp Inc., 1601 Willow Road, Menlo Park, California 94025, USA, beide nachfolgend
                nur &laquo;WhatsApp&raquo; genannt. Teilweise erfolgt die Verarbeitung der Nutzerdaten auf Servern von WhatsApp in
                den USA.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG&status=Active" target="_blank" rel="noreferrer"
                 >&laquo;EU-US Privacy Shield&raquo;</a> garantiert WhatsApp aber, dass die
                Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden. Zudem bietet WhatsApp
                weitere <a href="https://www.whatsapp.com/legal/#privacy-policy" target="_blank" rel="noreferrer" 
                    >Datenschutzinformationen</a> an.</p>
            <p>Um unseren Newsletter per WhatsApp empfangen zu k&ouml;nnen, ben&ouml;tigen Sie ein WhatsApp Nutzerkonto.
                Einzelheiten dar&uuml;ber, welche Daten WhatsApp bei der Registrierung erhebt, k&ouml;nnen Sie den vorgenannten
                Datenschutzinformationen von WhatsApp entnehmen.</p>
            <p>Falls Sie sich dann zu unserem Newsletter-Versand per WhatsApp anmelden, wird die von Ihnen w&auml;hrend des
                Anmeldevorgangs eingegebene Handy-Nummer durch WhatsApp verarbeitet. Zudem werden Ihre IP-Adresse sowie das Datum
                Ihrer Anmeldung nebst Uhrzeit gespeichert. Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in die
                &uuml;bersendung des Newsletters eingeholt, der Inhalt konkret beschrieben und auf diese Datenschutzerkl&auml;rung
                verwiesen.</p>
            <p>Rechtsgrundlage f&uuml;r den Versand des Newsletters und die Analyse ist Art. 6 Abs. 1 lit. a.) DSGVO.</p>
            <p>Die Einwilligung in den Newsletter-Versand k&ouml;nnen Sie gem&auml;ss Art. 7 Abs. 3 DSGVO jederzeit mit sofortiger
                Wirkung widerrufen. Hierzu m&uuml;ssen Sie uns lediglich &uuml;ber Ihren Widerruf in Kenntnis setzen. Ebenfalls
                k&ouml;nnen Sie den Newsletterempfang durch eine Einstellung in der WhatsApp-Software auf Ihrem Endger&auml;t
                blockieren.</p>
            <p>&nbsp;</p>
            <h2>Active Campaign</h2>
            <p>Diese Website nutzt die Dienste von Active Campaign für den Versand von Newslettern. Anbieter ist der US-Anbieter
                ActiveCampaign, LLC, 150 N. Michigan Ave Suite 1230, Chicago, IL, US, USA.</p>
            <p>Active Campaign ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert werden kann. Wenn
                Sie Daten zum Zwecke des Newsletterbezugs eingeben (z.B. Ihre E-Mail-Adresse), werden diese auf den Servern von
                Active Campaign in den USA gespeichert.</p>
            <p>Active Campaign verfügt über eine Zertifizierung nach dem &laquo;EU-US-Privacy-Shield&raquo;. Der
                &laquo;Privacy-Shield&raquo; ist ein Übereinkommen zwischen der Europäischen Union (EU) und den USA, das die
                Einhaltung europäischer Datenschutzstandards in den USA gewährleisten soll.</p>
            <p>Mit Hilfe von Active Campaign können wir unsere Newsletterkampagnen analysieren. Wenn Sie eine mit Active Campaign
                versendete E-Mail öffnen, verbindet sich eine in der E-Mail enthaltene Datei (sog. web-beacon) mit den Servern von
                Active Campaign in den USA. So kann festgestellt werden, ob eine Newsletter-Nachricht geöffnet und welche Links ggf.
                angeklickt wurden. Ausserdem werden technische Informationen erfasst (z.B. Zeitpunkt des Abrufs, IP-Adresse,
                Browsertyp und Betriebssystem). Diese Informationen können nicht dem jeweiligen Newsletter-Empfänger zugeordnet
                werden. Sie dienen ausschliesslich der statistischen Analyse von Newsletterkampagnen. Die Ergebnisse dieser Analysen
                können genutzt werden, um künftige Newsletter besser den Interessen der Empfänger anzupassen.</p>
            <p>Wenn Sie keine Analyse durch Active Campaign wollen, müssen Sie den Newsletter abbestellen. Dazu stellen wir in jeder
                Newsletternachricht einen entsprechenden Link zur Verfügung. Des Weiteren können Sie den Newsletter auch direkt auf
                unserer Website abbestellen.</p>
            <p>Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese
                Einwilligung jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmässigkeit der bereits erfolgten
                Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
            <p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus
                dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von unseren Servern als auch von den
                Servern von Active Campaign gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen
                für den Mitgliederbereich) bleiben hiervon unberührt.</p>
            <p>Näheres entnehmen Sie den Datenschutzbestimmungen von Active Campaign unter: <a
                href='https://www.activecampaign.com/privacy-policy/' target='_blank' rel='noreferrer'>https://www.activecampaign.com/privacy-policy/</a></p>
        </div>
    )
}

export default Privacy