import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

function Fahrer(props) {
    const [firstDate, setfirstDate] = useState('')
    const [secondDate, setsecondDate] = useState('')

    const [triedSubmit, settriedSubmit] = useState(false)
    const [canton, setcanton] = useState('')

    const nextStep = () => {
        settriedSubmit(true)
        if (triedSubmit !== '' && canton !== '' && firstDate !== '' && secondDate !== '') {
            props.setstep(props.step + 1)
        }
    }
    return (
        <div>
            <div>
                <div className="pb-4">
                    <span className='fw-700 fs-4'>
                        Fahrer/-in
                    </span>
                </div>
                <div className="pb-4">
                    <div className="row gx-4 gy-0 justify-content-center">
                        <div className="col-12 col-sm-6 col-lg-auto my-auto">
                            <div className="autoCheckbox textStartToEnd ">
                                <div className='pb-2 pb-sm-0'>
                                    <span className='fw-500 normalTextToWhite'>Was ist Ihr Geschlecht?</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-auto">
                            <div className="">
                                <div className='row gx-0 gy-3 autoCheckbox'>
                                    <div className="col-12 col-sm-6 pe-0 pe-sm-2">
                                        <label htmlFor="male" className="container1 w-100">
                                            <input checked id='male' type="radio" name='gender' hidden />
                                            <span className="checkmark checkmarkColorAuto ">Männlich</span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-sm-6 ps-0 ps-sm-2">
                                        <label htmlFor="female" className="container1 w-100">
                                            <input id='female' type="radio" name='gender' hidden />
                                            <span className="checkmark checkmarkColorAuto ">Weiblich</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-4">
                    <div className="row gx-4 gy-0 justify-content-center">
                        <div className="col-12 col-sm-6 col-lg-auto my-auto">
                            <div className="autoCheckbox textStartToEnd">
                                <div className='pb-2 pb-sm-0'>
                                    <span className='fw-500 normalTextToWhite'>Wann sind Sie geboren?</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-auto">
                            <div>
                                <DatePicker
                                    className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && firstDate === '') ? 'submitErrorInputStyle' : ''}`}
                                    onChange={e => setfirstDate(e)}
                                    selected={firstDate}
                                    dateFormat={'dd/MM/yyyy'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-4">
                    <div className="row gx-4 gy-0 justify-content-center">
                        <div className="col-12 col-sm-6 col-lg-auto my-auto">
                            <div className="autoCheckbox textStartToEnd">
                                <div className='pb-2 pb-sm-0'>
                                    <span className='fw-500 normalTextToWhite'>Wann wurde Ihr Führerausweis ausgestellt?</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-auto">
                            <div>
                                <DatePicker
                                    className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && secondDate === '') ? 'submitErrorInputStyle' : ''}`}
                                    onChange={e => setsecondDate(e)}
                                    selected={secondDate}
                                    dateFormat={'dd/MM/yyyy'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-4">
                    <div className="row gx-4 gy-0 justify-content-center">
                        <div className="col-12 col-sm-6 col-lg-auto my-auto">
                            <div className="autoCheckbox textStartToEnd">
                                <div className='pb-2 pb-sm-0'>
                                    <span className='fw-500 normalTextToWhite'>Was ist Ihre Nationalität?</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-auto">
                            <div>
                                <select name="" id="" defaultValue={'CH'} className='krankenInputStyle form-select krankenInputStepStyle p-3 autoCheckbox'>
                                    <option value="CH">Schweiz</option>
                                    <option value="DE">Deutschland</option>
                                    <option value="AT">Österreich</option>
                                    <optgroup label="A">
                                        <option value="AF">Afghanistan</option>
                                        <option value="EG">Ägypten</option>
                                        <option value="AX">Åland</option>
                                        <option value="AL">Albanien</option>
                                        <option value="DZ">Algerien</option>
                                        <option value="AS">Amerikanisch-Samoa</option>
                                        <option value="VI">Amerikanische Jungferninseln</option>
                                        <option value="AD">Andorra</option>
                                        <option value="AO">Angola</option>
                                        <option value="AI">Anguilla</option>
                                        <option value="AQ">Antarktis</option>
                                        <option value="AG">Antigua und Barbuda</option>
                                        <option value="GQ">Äquatorialguinea</option>
                                        <option value="AR">Argentinien</option>
                                        <option value="AM">Armenien</option>
                                        <option value="AW">Aruba</option>
                                        <option value="AC">Ascension</option>
                                        <option value="AZ">Aserbaidschan</option>
                                        <option value="ET">Äthiopien</option>
                                        <option value="AU">Australien</option>
                                    </optgroup>
                                    <optgroup label="B">
                                        <option value="BS">Bahamas</option>
                                        <option value="BH">Bahrain</option>
                                        <option value="BD">Bangladesch</option>
                                        <option value="BB">Barbados</option>
                                        <option value="BY">Belarus (Weißrussland)</option>
                                        <option value="BE">Belgien</option>
                                        <option value="BZ">Belize</option>
                                        <option value="BJ">Benin</option>
                                        <option value="BM">Bermuda</option>
                                        <option value="BT">Bhutan</option>
                                        <option value="BO">Bolivien</option>
                                        <option value="BA">Bosnien und Herzegowina</option>
                                        <option value="BW">Botswana</option>
                                        <option value="BV">Bouvetinsel</option>
                                        <option value="BR">Brasilien</option>
                                        <option value="VG">Britische Jungferninseln</option>
                                        <option value="IO">Britisches Territorium im Indischen Ozean</option>
                                        <option value="BN">Brunei Darussalam</option>
                                        <option value="BG">Bulgarien</option>
                                        <option value="BF">Burkina Faso</option>
                                        <option value="BI">Burundi</option>
                                    </optgroup>
                                    <optgroup label="C">
                                        <option value="EA">Ceuta, Melilla</option>
                                        <option value="CL">Chile</option>
                                        <option value="CN">Volksrepublik China</option>
                                        <option value="CP">Clipperton</option>
                                        <option value="CK">Cookinseln</option>
                                        <option value="CR">Costa Rica</option>
                                        <option value="CI">Côte d'Ivoire (Elfenbeinküste)</option>
                                    </optgroup>
                                    <optgroup label="D">
                                        <option value="DK">Dänemark</option>
                                        <option value="DE">Deutschland</option>
                                        <option value="DG">Diego Garcia</option>
                                        <option value="DM">Dominica</option>
                                        <option value="DO">Dominikanische Republik</option>
                                        <option value="DJ">Dschibuti</option>
                                    </optgroup>
                                    <optgroup label="E">
                                        <option value="EC">Ecuador</option>
                                        <option value="SV">El Salvador</option>
                                        <option value="ER">Eritrea</option>
                                        <option value="EE">Estland</option>
                                    </optgroup>
                                    <optgroup label="F">
                                        <option value="FK">Falklandinseln</option>
                                        <option value="FO">Färöer</option>
                                        <option value="FJ">Fidschi</option>
                                        <option value="FI">Finnland</option>
                                        <option value="FR">Frankreich</option>
                                        <option value="GF">Französisch-Guayana</option>
                                        <option value="PF">Französisch-Polynesien</option>
                                    </optgroup>
                                    <optgroup label="G">
                                        <option value="GA">Gabun</option>
                                        <option value="GM">Gambia</option>
                                        <option value="GE">Georgien</option>
                                        <option value="GH">Ghana</option>
                                        <option value="GI">Gibraltar</option>
                                        <option value="GD">Grenada</option>
                                        <option value="GR">Griechenland</option>
                                        <option value="GL">Grönland</option>
                                        <option value="GB">Großbritannien</option>
                                        <option value="GP">Guadeloupe</option>
                                        <option value="GU">Guam</option>
                                        <option value="GT">Guatemala</option>
                                        <option value="GG">Guernsey (Kanalinsel)</option>
                                        <option value="GN">Guinea</option>
                                        <option value="GW">Guinea-Bissau</option>
                                        <option value="GY">Guyana</option>
                                    </optgroup>
                                    <optgroup label="H">
                                        <option value="HT">Haiti</option>
                                        <option value="HM">Heard- und McDonald-Inseln</option>
                                        <option value="HN">Honduras</option>
                                        <option value="HK">Hongkong</option>
                                    </optgroup>
                                    <optgroup label="I">
                                        <option value="IN">Indien</option>
                                        <option value="ID">Indonesien</option>
                                        <option value="IM">Insel Man</option>
                                        <option value="IQ">Irak</option>
                                        <option value="IR">Iran</option>
                                        <option value="IE">Irland</option>
                                        <option value="IS">Island</option>
                                        <option value="IL">Israel</option>
                                        <option value="IT">Italien</option>
                                    </optgroup>
                                    <optgroup label="J">
                                        <option value="JM">Jamaika</option>
                                        <option value="JP">Japan</option>
                                        <option value="YE">Jemen</option>
                                        <option value="JE">Jersey (Kanalinsel)</option>
                                        <option value="JO">Jordanien</option>
                                    </optgroup>
                                    <optgroup label="K">
                                        <option value="KY">Kaimaninseln</option>
                                        <option value="KH">Kambodscha</option>
                                        <option value="CM">Kamerun</option>
                                        <option value="CA">Kanada</option>
                                        <option value="IC">Kanarische Inseln</option>
                                        <option value="CV">Kap Verde</option>
                                        <option value="KZ">Kasachstan</option>
                                        <option value="QA">Katar</option>
                                        <option value="KE">Kenia</option>
                                        <option value="KG">Kirgisistan</option>
                                        <option value="KI">Kiribati</option>
                                        <option value="CC">Kokosinseln</option>
                                        <option value="CO">Kolumbien</option>
                                        <option value="KM">Komoren</option>
                                        <option value="CD">Demokratische Republik Kongo</option>
                                        <option value="KP">Demokratische Volksrepublik Korea (Nordkorea)</option>
                                        <option value="KR">Republik Korea (Südkorea)</option>
                                        <option value="KOS">Kosovo</option>
                                        <option value="HR">Kroatien</option>
                                        <option value="CU">Kuba</option>
                                        <option value="KW">Kuwait</option>
                                    </optgroup>
                                    <optgroup label="L">
                                        <option value="LA">Laos</option>
                                        <option value="LS">Lesotho</option>
                                        <option value="LV">Lettland</option>
                                        <option value="LB">Libanon</option>
                                        <option value="LR">Liberia</option>
                                        <option value="LY">Libyen</option>
                                        <option value="LI">Liechtenstein</option>
                                        <option value="LT">Litauen</option>
                                        <option value="LU">Luxemburg</option>
                                    </optgroup>
                                    <optgroup label="M">
                                        <option value="MO">Macao</option>
                                        <option value="MG">Madagaskar</option>
                                        <option value="MW">Malawi</option>
                                        <option value="MY">Malaysia</option>
                                        <option value="MV">Malediven</option>
                                        <option value="ML">Mali</option>
                                        <option value="MT">Malta</option>
                                        <option value="MA">Marokko</option>
                                        <option value="MH">Marshallinseln</option>
                                        <option value="MQ">Martinique</option>
                                        <option value="MR">Mauretanien</option>
                                        <option value="MU">Mauritius</option>
                                        <option value="YT">Mayotte</option>
                                        <option value="MK">Mazedonien</option>
                                        <option value="MX">Mexiko</option>
                                        <option value="FM">Mikronesien</option>
                                        <option value="MD">Moldawien (Republik Moldau)</option>
                                        <option value="MC">Monaco</option>
                                        <option value="MN">Mongolei</option>
                                        <option value="ME">Montenegro</option>
                                        <option value="MS">Montserrat</option>
                                        <option value="MZ">Mosambik</option>
                                        <option value="MM">Myanmar (Burma)</option>
                                    </optgroup>
                                    <optgroup label="N">
                                        <option value="NA">Namibia</option>
                                        <option value="NR">Nauru</option>
                                        <option value="NP">Nepal</option>
                                        <option value="NC">Neukaledonien</option>
                                        <option value="NZ">Neuseeland</option>
                                        <option value="NI">Nicaragua</option>
                                        <option value="NL">Niederlande</option>
                                        <option value="AN">Niederländische Antillen</option>
                                        <option value="NE">Niger</option>
                                        <option value="NG">Nigeria</option>
                                        <option value="NU">Niue</option>
                                        <option value="MP">Nördliche Marianen</option>
                                        <option value="NF">Norfolkinsel</option>
                                        <option value="NO">Norwegen</option>
                                    </optgroup>
                                    <optgroup label="O">
                                        <option value="OM">Oman</option>
                                        <option value="XO">Orbit</option>
                                        <option value="AT">Österreich</option>
                                        <option value="TL">Osttimor (Timor-Leste)</option>
                                    </optgroup>
                                    <optgroup label="P">
                                        <option value="PK">Pakistan</option>
                                        <option value="PS">Palästinensische Autonomiegebiete</option>
                                        <option value="PW">Palau</option>
                                        <option value="PA">Panama</option>
                                        <option value="PG">Papua-Neuguinea</option>
                                        <option value="PY">Paraguay</option>
                                        <option value="PE">Peru</option>
                                        <option value="PH">Philippinen</option>
                                        <option value="PN">Pitcairninseln</option>
                                        <option value="PL">Polen</option>
                                        <option value="PT">Portugal</option>
                                        <option value="PR">Puerto Rico</option>
                                    </optgroup>

                                    <optgroup label="Q"></optgroup>

                                    <optgroup label="R">
                                        <option value="TW">Republik China (Taiwan)</option>
                                        <option value="CG">Republik Kongo</option>
                                        <option value="RE">Réunion</option>
                                        <option value="RW">Ruanda</option>
                                        <option value="RO">Rumänien</option>
                                        <option value="RU">Russische Föderation</option>
                                    </optgroup>
                                    <optgroup label="S">
                                        <option value="BL">Saint-Barthélemy</option>
                                        <option value="MF">Saint-Martin</option>
                                        <option value="SB">Salomonen</option>
                                        <option value="ZM">Sambia</option>
                                        <option value="WS">Samoa</option>
                                        <option value="SM">San Marino</option>
                                        <option value="ST">São Tomé und Príncipe</option>
                                        <option value="SA">Saudi-Arabien</option>
                                        <option value="SE">Schweden</option>
                                        <option value="CH">Schweiz</option>
                                        <option value="SN">Senegal</option>
                                        <option value="RS">Serbien</option>
                                        <option value="SC">Seychellen</option>
                                        <option value="SL">Sierra Leone</option>
                                        <option value="ZW">Simbabwe</option>
                                        <option value="SG">Singapur</option>
                                        <option value="SK">Slowakei</option>
                                        <option value="SI">Slowenien</option>
                                        <option value="SO">Somalia</option>
                                        <option value="ES">Spanien</option>
                                        <option value="LK">Sri Lanka</option>
                                        <option value="SH">St. Helena</option>
                                        <option value="KN">St. Kitts und Nevis</option>
                                        <option value="LC">St. Lucia</option>
                                        <option value="PM">Saint-Pierre und Miquelon</option>
                                        <option value="VC">St. Vincent und die Grenadinen</option>
                                        <option value="ZA">Südafrika</option>
                                        <option value="SD">Sudan</option>
                                        <option value="GS">Südgeorgien und die Südlichen Sandwichinseln</option>
                                        <option value="SR">Suriname</option>
                                        <option value="SJ">Svalbard und Jan Mayen</option>
                                        <option value="SZ">Swasiland</option>
                                        <option value="SY">Syrien</option>
                                    </optgroup>
                                    <optgroup label="T">
                                        <option value="TJ">Tadschikistan</option>
                                        <option value="TZ">Tansania</option>
                                        <option value="TH">Thailand</option>
                                        <option value="TG">Togo</option>
                                        <option value="TK">Tokelau</option>
                                        <option value="TO">Tonga</option>
                                        <option value="TT">Trinidad und Tobago</option>
                                        <option value="TA">Tristan da Cunha</option>
                                        <option value="TD">Tschad</option>
                                        <option value="CZ">Tschechische Republik</option>
                                        <option value="TN">Tunesien</option>
                                        <option value="TR">Türkei</option>
                                        <option value="TM">Turkmenistan</option>
                                        <option value="TC">Turks- und Caicosinseln</option>
                                        <option value="TV">Tuvalu</option>
                                    </optgroup>
                                    <optgroup label="U">
                                        <option value="UG">Uganda</option>
                                        <option value="UA">Ukraine</option>
                                        <option value="HU">Ungarn</option>
                                        <option value="UY">Uruguay</option>
                                        <option value="UZ">Usbekistan</option>
                                    </optgroup>
                                    <optgroup label="V">
                                        <option value="VU">Vanuatu</option>
                                        <option value="VA">Vatikanstadt</option>
                                        <option value="VE">Venezuela</option>
                                        <option value="AE">Vereinigte Arabische Emirate</option>
                                        <option value="US">Vereinigte Staaten von Amerika (USA)</option>
                                        <option value="GB">Vereinigtes Königreich Großbritannien und Nordirland</option>
                                        <option value="VN">Vietnam</option>
                                    </optgroup>
                                    <optgroup label="W">
                                        <option value="WF">Wallis und Futuna</option>
                                        <option value="CX">Weihnachtsinsel</option>
                                        <option value="EH">Westsahara</option>
                                    </optgroup>
                                    <optgroup label="Z">
                                        <option value="CF">Zentralafrikanische Republik</option>
                                        <option value="CY">Zypern</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-4">
                    <div className="row gx-4 gy-0 justify-content-center">
                        <div className="col-12 col-sm-6 col-lg-auto my-auto">
                            <div className="autoCheckbox textStartToEnd">
                                <div className='pb-2 pb-sm-0'>
                                    <span className='fw-500 normalTextToWhite'>In welchem Ort wohnen Sie?</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-auto">
                            <div>
                                <input type="text" onChange={(e) => {setcanton(e.target.value)}} className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && canton === '') ? 'submitErrorInputStyle' : ''}`}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-4">
                    <div className="row gx-4 gy-0 justify-content-center">
                        <div className="col-12 col-sm-6 col-lg-auto my-auto">
                            <div className="autoCheckbox textStartToEnd ">
                                <div className='pb-2 pb-sm-0'>
                                    <span className='fw-500 normalTextToWhite'>Fahren Sie das Auto am häufigsten?</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-auto">
                            <div className="">
                                <div className='row gx-0 gy-3 autoCheckbox'>
                                    <div className="col-12 col-sm-6 pe-0 pe-sm-2">
                                        <label htmlFor="JaDrive" className="container1 w-100">
                                            <input checked id='JaDrive' type="radio" name='driveCar' hidden />
                                            <span className="checkmark checkmarkColorAuto ">Ja</span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-sm-6 ps-0 ps-sm-2">
                                        <label htmlFor="NeinDrive" className="container1 w-100">
                                            <input  id='NeinDrive' type="radio" name='driveCar' hidden />
                                            <span className="checkmark checkmarkColorAuto ">Nein</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row gx-4 gy-0 justify-content-center">
                        <div className="col-12 col-sm-6 col-lg-auto my-auto">
                            <div className="autoCheckbox textStartToEnd ">
                                <div className='pb-2 pb-sm-0'>
                                    <span className='fw-500 normalTextToWhite'>Fahren Sie mehr als 7 000 Kilometer pro Jahr?</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-auto">
                            <div className="">
                                <div className='row gx-0 gy-3 autoCheckbox'>
                                    <div className="col-12 col-sm-6 pe-0 pe-sm-2">
                                        <label htmlFor="Ja7000" className="container1 w-100">
                                            <input checked id='Ja7000' type="radio" name='moreThan7000' hidden />
                                            <span className="checkmark checkmarkColorAuto ">Ja</span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-sm-6 ps-0 ps-sm-2">
                                        <label htmlFor="Nein7000" className="container1 w-100">
                                            <input id='Nein7000' type="radio" name='moreThan7000' hidden />
                                            <span className="checkmark checkmarkColorAuto ">Nein</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row gx-4 gy-0 justify-content-center pt-5">
                <div className="col-6 col-sm-auto">
                    <div>
                        <button className='autoBtnStyle' onClick={() => { props.setstep(props.step - 1) }}>
                            Zurück
                        </button>
                    </div>
                </div>
                <div className="col-6 col-sm-auto">
                    <div>
                        <button type='button' className='autoBtnStyle' onClick={nextStep}>
                            Weiter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Fahrer