import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper';
import { useState } from 'react';
import 'swiper/css';
import 'swiper/css/effect-fade';

import { ReactComponent as Dental } from '../../assets/images/plus/dental.svg'
import { ReactComponent as DentalDark } from '../../assets/images/plus/dentalDark.svg'

import { ReactComponent as Sport } from '../../assets/images/plus/sport.svg'
import { ReactComponent as SportDark } from '../../assets/images/plus/sportDark.svg'

import { ReactComponent as Massage } from '../../assets/images/plus/massage.svg'
import { ReactComponent as MassageDark } from '../../assets/images/plus/massageDark.svg'

import { ReactComponent as Law } from '../../assets/images/plus/law.svg'
import { ReactComponent as LawDark } from '../../assets/images/plus/lawDark.svg'

function PlusTabs(props) {
    const [swiperInstance, setSwiperInstance] = useState(0)
    const [active, setactive] = useState(0)



    return (
        <>
            <div className="container-xl px-4 px-xl-0">
                <div className='pb-4'>
                    <span className='fw-700 fs-1 normalTextToWhite'>Wählbare Leistungspakete aus allen Bereichen der Gesundheitsförderung. </span>
                </div>
                <div className='pb-0'>
                    <div className="row g-4 justify-content-center mb-5">
                        <div className="col-auto" >
                            <div className={`plusSliderButton px-2 ${(active === 0) ? 'sliderBtnEffect' : ''}`} onClick={() => { swiperInstance.slideToLoop(0, undefined, false); setactive(0) }}>
                                <span className='fw-600 fs-5 normalTextToWhite'>Dental</span>
                            </div>
                        </div>
                        <div className="col-auto" >
                            <div className={`plusSliderButton px-2 ${(active === 1) ? 'sliderBtnEffect' : ''}`} onClick={() => { swiperInstance.slideToLoop(1, undefined, false); setactive(1) }}>
                                <span className='fw-600 fs-5 normalTextToWhite'>Sport</span>
                            </div>
                        </div>

                        <div className="col-auto" >
                            <div className={`plusSliderButton px-2 ${(active === 2) ? 'sliderBtnEffect' : ''}`} onClick={() => { swiperInstance.slideToLoop(2, undefined, false); setactive(2) }}>
                                <span className='fw-600 fs-5 normalTextToWhite'>Massage</span>
                            </div>
                        </div>
                        <div className="col-auto" >
                            <div className={`plusSliderButton px-2 ${(active === 3) ? 'sliderBtnEffect' : ''}`} onClick={() => { swiperInstance.slideToLoop(3, undefined, false); setactive(3) }}>
                                <span className='fw-600 fs-5 normalTextToWhite'>Law</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Swiper
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    spaceBetween={30}
                    effect={"fade"}

                    fadeEffect={{
                        crossFade: true
                    }}
                    loop={true}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    modules={[EffectFade, Autoplay]}
                    className="mySwiper mb-4 mb-md-2 mb-lg-0"
                    onSlideChange={(swiper) => { setactive((swiper.activeIndex - 1 > 3) ? 0 : swiper.activeIndex - 1) }}

                >

                    <SwiperSlide>
                        <div className="plusSliderBackground text-start">
                            <div className="row gx-5 gy-0 justify-content-center w-100">
                                <div className="col-12 col-sm-6 col-md-auto my-sm-auto order-2 order-sm-1">
                                    <div className='pb-3'>
                                        <span className='fs-1 fw-700 normalTextToWhite'>Dental</span>
                                    </div>
                                    <div>
                                        <span className='fs-5 fw-600 normalTextToWhite'>Beiträge an der jährlichen Zahnreinigung. </span>
                                    </div>
                                </div>
                                <div className="col-8 col-sm col-lg-5 col-xl-5 col-xxl-5 order-1 order-sm-2 my-sm-auto">
                                    {props.darkMode
                                        ? <DentalDark className="img-fluid" />
                                        : <Dental className="img-fluid" />
                                    }
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="plusSliderBackground text-start">

                            <div className="row gx-5 gy-0 justify-content-center w-100">
                                <div className="col-12 col-sm-6 col-md-auto my-sm-auto order-2 order-sm-1">
                                    <div className='pb-3'>
                                        <span className='fs-1 fw-700 normalTextToWhite'>Sport</span>
                                    </div>
                                    <div>
                                        <span className='fs-5 fw-600 normalTextToWhite'>Beiträge am Sportverein oder Fitnessabo. </span>
                                    </div>
                                </div>
                                <div className="col-8 col-sm col-lg-5 col-xl-5 col-xxl-5 order-1 order-sm-2 my-sm-auto text-center">
                                    {props.darkMode
                                        ? <SportDark className="img-fluid"  />
                                        : <Sport className="img-fluid"  />
                                    }
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="plusSliderBackground text-start">

                            <div className="row gx-5 gy-0 justify-content-center w-100">
                                <div className="col-12 col-sm-6 col-md-auto my-sm-auto order-2 order-sm-1">
                                    <div className='pb-3'>
                                        <span className='fs-1 fw-700 normalTextToWhite'>Massage</span>
                                    </div>
                                    <div>
                                        <span className='fs-5 fw-600 normalTextToWhite'>Beiträge an den Kosten von Massagen  </span>
                                    </div>
                                </div>
                                <div className="col-8 col-sm col-lg-5 col-xl-5 col-xxl-5 order-1 order-sm-2 my-sm-auto">
                                    {props.darkMode
                                        ? <MassageDark className="img-fluid" />
                                        : <Massage className="img-fluid" />
                                    }
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="plusSliderBackground text-start">

                            <div className="row gx-5 gy-0 justify-content-center w-100">
                                <div className="col-12 col-sm-6 col-md-auto my-sm-auto order-2 order-sm-1">
                                    <div className='pb-3'>
                                        <span className='fs-1 fw-700 normalTextToWhite'>Law</span>
                                    </div>
                                    <div>
                                        <span className='fs-5 fw-600 normalTextToWhite'>Beiträge an der Rechtsschutzversicherung </span>
                                    </div>
                                </div>
                                <div className="col-8 col-sm col-lg-5 col-xl-5 col-xxl-5 order-1 order-sm-2 my-sm-auto">
                                    {props.darkMode
                                        ? <LawDark className="img-fluid" />
                                        : <Law className="img-fluid" />
                                    }
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default PlusTabs