import React, {useState} from 'react'
import { ReactComponent as First } from '../../assets/images/krakenmainimg.svg'
import { ReactComponent as FirstDark } from '../../assets/images/krankenmainimgDark.svg'

function FirstSectionKranken(props) {
    const [value, setValue] = useState(new Date());
    return (
        <>
            <div className="container-xl px-4 px-xl-0 bodyCounterPadding">
                <div className="row justify-content-between gy-5 gy-md-0 gx-5">
                    <div className="col-12 col-md-6 col-lg-6 my-md-auto">
                        <div className='pb-3 text-start'>
                            <span className='fw-700 fs-1 firstBlueTitle'>Krankenkasse in der Schweiz</span>
                        </div>
                        <div className='text-start pb-4'>
                            <span className='normalTextToWhite fs-5'>
                                Es ist gut, dass der Wettbewerb unter Krankenkassen in der Schweiz stark ist, da dies dazu beitragen kann, dass die Qualität der Leistungen hoch bleibt und die Kosten für die Versicherten niedrig gehalten werden. Es ist wichtig, dass Personen in der Schweiz ihre Prämien vergleichen, um sicherzustellen, dass sie die für sie geeignete Krankenkasse wählen und nicht zu viel für ihre Versicherung bezahlen.
                            </span>
                        </div>
                        <div className='text-start'>
                            <button onClick={props.toSteps} className='hausratBtn py-2'>
                                Jetzt vergleichen
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 my-md-auto">

                        <div className="">
                            <div>
                                {props.darkMode

                                    ? <FirstDark className="img-fluid" />
                                    : <First className="img-fluid" />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FirstSectionKranken