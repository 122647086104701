import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules

import { ReactComponent as Dental } from '../../assets/images/plus/dental.svg'
import { ReactComponent as DentalDark } from '../../assets/images/plus/dentalDark.svg'

import { ReactComponent as Sport } from '../../assets/images/plus/sport.svg'
import { ReactComponent as SportDark } from '../../assets/images/plus/sportDark.svg'

import { ReactComponent as Massage } from '../../assets/images/plus/massage.svg'
import { ReactComponent as MassageDark } from '../../assets/images/plus/massageDark.svg'

import { ReactComponent as Law } from '../../assets/images/plus/law.svg'
import { ReactComponent as LawDark } from '../../assets/images/plus/lawDark.svg'

function PlusTabsMobile(props) {
    return (
        <>
            <div className="fs-1 normalTextToWhite fw-700">
                Wählbare Leistungspakete aus allen Bereichen der Gesundheitsförderung.
            </div>
            <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper pt-5 pb-4 px-4"
            >
                <SwiperSlide className="">
                    <div>
                        <div className="styleofplustabsmobile p-4">
                            <div>
                                {props.darkMode
                                    ? <DentalDark width={150} className="img-fluid" />
                                    : <Dental width={150} className="img-fluid" />
                                }
                            </div>
                            <div>
                                <p className="mobiletabstitle">Dental</p>
                                <p className="mobiletabssubtitle">Beiträge an der jährlichen Zahnreinigung.</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <div className="styleofplustabsmobile p-4">
                            <div>
                                {props.darkMode
                                    ? <SportDark width={150} className="img-fluid" />
                                    : <Sport width={150} className="img-fluid" />
                                }
                            </div>
                            <div>
                                <p className="mobiletabstitle">Sport</p>
                                <p className="mobiletabssubtitle">Beiträge am Sportverein oder Fitnessabo.</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="">
                    <div>
                        <div className="styleofplustabsmobile p-4">
                            <div>
                                {props.darkMode
                                    ? <MassageDark width={150} className="img-fluid" />
                                    : <Massage width={150} className="img-fluid" />
                                }
                            </div>
                            <div>
                                <p className="mobiletabstitle">Massage</p>
                                <p className="mobiletabssubtitle">Beiträge an den Kosten von Massagen</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <div className="styleofplustabsmobile p-4">
                            <div>
                                {props.darkMode
                                    ? <LawDark width={150} className="img-fluid" />
                                    : <Law width={150} className="img-fluid" />
                                }
                            </div>
                            <div>
                                <p className="mobiletabstitle">Law</p>
                                <p className="mobiletabssubtitle">Beiträge an der Rechtsschutzversicherung</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
export default PlusTabsMobile
