import React from 'react'
import UploadFileStep from './autoSecondRadio/UploadFileStep'
import { useState, useEffect, useRef } from 'react'
import SecondStep from './autoSecondRadio/SecondStep'
import ThirdStep from './autoSecondRadio/ThirdStep'

function SecondRadioChecked() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [screenWidth])

    const [step, setstep] = useState(0)

    const scrollDiv1 = useRef();
    useEffect(() => {

        var elem = scrollDiv1
        window.scrollTo(
            {
                top: elem.current.offsetTop + ((screenWidth > 500.98) ? 280 : 950),
                behavior: "smooth"
            });
    })
    return (
        <div className='pt-5 mt-5'>
            <form action="" className='mb-0'>
                <div className="wrapDiv container-xl px-0">
                    <div className="" ref={scrollDiv1}>
                        {
                            {
                                0: <UploadFileStep step={step} setstep={setstep} />,
                                1: <SecondStep step={step} setstep={setstep} />,
                                2: <ThirdStep step={step} setstep={setstep} />
                            }[step]
                        }
                    </div>
                </div>

            </form>
        </div>
    )
}

export default SecondRadioChecked