import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as BlueBg } from '../../assets/images/plus/blueBg.svg'
import { ReactComponent as BlueBgDark } from '../../assets/images/plus/blueBgDark.svg'
import { ReactComponent as MobIlu } from '../../assets/images/plus/mobileIlu.svg'
import { ReactComponent as Support } from '../../assets/images/plus/support24.svg'
import { ReactComponent as SupportDark } from '../../assets/images/plus/support24Dark.svg'
import { ReactComponent as Lock } from '../../assets/images/plus/lock.svg'
import { ReactComponent as LockDark } from '../../assets/images/plus/lockDark.svg'
import { ReactComponent as Collab } from '../../assets/images/plus/collab.svg'
import { ReactComponent as CollabDark } from '../../assets/images/plus/collabDark.svg'
import { ReactComponent as Vid1 } from '../../assets/images/plus/vid1.svg'
import { ReactComponent as Checkbox1 } from '../../assets/images/plus/checkboxSport.svg'
import { ReactComponent as Checkbox2 } from '../../assets/images/plus/checkboxReisen.svg'
import { ReactComponent as Checkbox3 } from '../../assets/images/plus/checkboxTooth.svg'

import { useState, useEffect } from 'react'
function FirstSection(props) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    const [step, setstep] = useState(1)
    const [checkboxes, setcheckboxes] = useState(0)

    const inputChecked = (e) => {
        if (e.target.checked) {
            setcheckboxes(checkboxes + 1)
        }
        else {
            setcheckboxes(checkboxes - 1)
        }
    }

    const [vorname, setVorname] = useState('')
    const [triedSubmit, settriedSubmit] = useState(false)

    const toStepTwo = () => {
        settriedSubmit(true)
        if (vorname) {
            setstep(step + 1)
            settriedSubmit(false)

        }
    }

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [street, setStreet] = useState('')
    const [ort, setOrt] = useState('')
    const [successSubmit, setSuccessSubmit] = useState(false)
    const step3Submit = () => {
        settriedSubmit(true)
        if (vorname && name && email && phone && street && ort) {
            setSuccessSubmit(true)
        }
    }
    return (
        <>
            <div className="bodyCounterPadding pb-sm-0">
                <div className="blueBg px-4 px-xl-0  ">
                    {(screenWidth > 991.98) && (
                        <>
                            {props.darkMode
                                ? <BlueBgDark />
                                : <BlueBg />
                            }
                        </>

                    )}
                    {(screenWidth < 575.98) && (


                        <div className="blueBg pt-4">
                            <div className="row gy-4">
                                <div className="col">
                                    <MobIlu className="img-fluid" />

                                </div>
                                <div className="col-auto my-md-auto">
                                    <div className="text-start">
                                        <span className='fw-700 fs-3 normalTextToWhite text-start ' style={{ color: 'white' }}>Eine Mitgliedschaft die sich auszahlt!</span>
                                    </div>
                                </div>
                            </div>
                            <div className="text-start py-4">
                                <span className='fw-400 fs-4 normalTextToWhite ' style={{ color: 'white' }}>Unsere Kunden erhalten im Durchschnitt zusätzliche Leistungen im Umfang von 328 CHF pro Jahr durch die korrekte Abrechnung und zusätzliche Leistungserbringung des Vereins.
                                </span>
                            </div>
                        </div>
                    )}

                </div>
            </div>
            <div className="container-xl px-4 px-xl-0  text-start ">
                <div className="row gx-5 justify-content-between ">
                    {(screenWidth > 575.98) && (
                        <div className="col-12 col-lg-6 my-lg-auto order-1 order-md-1 bodyCounterPadding">
                            <div className='pb-5'>
                                <span className='plusTitle fw-700 triaryColor '>Eine Mitgliedschaft die sich auszahlt!</span>
                            </div>
                            <div className='pb-5'>
                                <span className='fs-5 fw-400 normalTextToWhite'>Unsere Kunden erhalten im Durchschnitt zusätzliche Leistungen im Umfang von 328 CHF pro Jahr durch die korrekte Abrechnung und zusätzliche Leistungserbringung des Vereins.
                                </span>
                            </div>
                            <div className='pb-5'>
                                <button className='plusBtn fw-700 w-30 py-2 px-4 fs-5' >Arten der Mitgliedschaft</button>
                            </div>
                            <div>
                                <div className="row gx-5 gy-4">
                                    <div className="col-5 pe-0">
                                        <div className="row gx-2 gy-0">
                                            <div className="col-auto">
                                                {props.darkMode
                                                    ? <SupportDark />
                                                    : <Support />
                                                }
                                            </div>
                                            <div className="col my-auto">

                                                <span className='fw-600 normalTextToWhite fs-5'>24/7 Support</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="row gx-2 gy-0">
                                            <div className="col-auto">
                                                {props.darkMode
                                                    ? <LockDark />
                                                    : <Lock />
                                                }
                                            </div>
                                            <div className="col my-auto">
                                                <span className='fw-600 normalTextToWhite fs-5'>Reguliert</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row gx-2 gy-0">
                                            <div className="col-auto">
                                                {props.darkMode
                                                    ? <CollabDark />
                                                    : <Collab />
                                                }
                                            </div>
                                            <div className="col my-auto">
                                                <span className='fw-600 normalTextToWhite fs-5'>Zusammenarbeit mit mehreren Kassen</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-5">
                                        <div className="row gx-2 gy-0">
                                            <div className="col-auto">
                                                {props.darkMode
                                                    ? <UsersDark />
                                                    : <Users />
                                                }
                                            </div>
                                            <div className="col my-auto">
                                                <span className='fw-600 normalTextToWhite fs-5'>Dolor sit amet</span>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="col-12 col-lg-6 order-3 order-md-2 bodyCounterPadding my-lg-auto">
                        <div className="plusForm p-4 p-md-5 ms-auto">
                            <div className="text-end pb-4 pb-sm-0">
                                <span className='fs-3 fw-700' style={{ color: '#fff' }}>{step}/3</span>
                            </div>
                            <div className='pb-5 text-center text-md-start'>
                                <span className='fs-3 fw-700 plusFormTitle' style={{ color: 'white' }}>Lohnt sich eine Mitgliedschaft?</span>
                            </div>
                            {/* <div className="pb-4 text-center text-md-start">
                                <span className='fs-5 text-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                            </div> */}
                            <div style={{ display: (step === 1) ? 'block' : 'none' }}>
                                <div className='pb-4'>
                                    <div className='pb-2'>
                                        <span className='fw-700' style={{ color: 'white' }}>Wie heissen Sie?</span>
                                    </div>
                                    <div >
                                        <input onChange={(e) => { setVorname(e.target.value) }} className={`form-control plusInput p-3 ${(triedSubmit && vorname === '') ? 'submitErrorInputStyle' : ''} `} type="text" placeholder='Vorname*' style={{ background: 'white' }} />
                                    </div>
                                </div>

                                <div className='' style={{ position: 'relative' }}>
                                    <div className='pb-2'>
                                        <span className='fw-700' style={{ color: 'white' }}>Ihr Familienstand</span>
                                    </div>
                                    <div>
                                        <select name="" id="" className='form-select plusInput plusSelectArrow p-3' style={{ color: '#5a5a5a !important' }}>
                                            <option value="Einzelperson">Einzelperson</option>
                                            <option value="Paar">Paar</option>
                                            <option value="Familie">Familie</option>
                                        </select>
                                    </div>

                                </div>
                                <div className='text-end pt-5 col-6 ms-auto'>
                                    <button className='px-4 px-sm-5 py-3 w-100 finanuWhiteBtn' onClick={toStepTwo}>Weiter</button>
                                </div>
                            </div>
                            <div style={{ display: (step === 2) ? 'block' : 'none' }}>
                                <div className='pb-4 text-center text-md-start'>
                                    <span className='fw-700' style={{ color: 'white' }}>Was ist Ihnen wichtig?</span>
                                </div>
                                <div>
                                    <div className="row gx-4 gy-4 gy-md-0 gy-lg-4 gy-xxl-0 justify-content-center">
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xxl-4">
                                            <div className="row g-0 justify-content-center">
                                                <div className="col-auto my-auto">
                                                    <label className="containerFinPlus">
                                                        <input type="checkbox" name='membership' onChange={inputChecked} hidden />
                                                        <span className="checkmarkFinPlus"></span>
                                                    </label>
                                                </div>
                                                <div className="col-auto col-sm text-center">
                                                    <Checkbox1 style={{ width: '90px' }} />
                                                    <div className="text-center pt-3" style={{ height: '50px' }}>
                                                        <span className='fw-700' style={{ color: 'white' }}>Sport/ Fitness</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xxl-4">
                                            <div className="row g-0 justify-content-center">
                                                <div className="col-auto my-auto">
                                                    <label className="containerFinPlus">
                                                        <input type="checkbox" name='membership' onChange={inputChecked} hidden />
                                                        <span className="checkmarkFinPlus"></span>
                                                    </label>
                                                </div>
                                                <div className="col-auto col-sm text-center">
                                                    <Checkbox2 style={{ width: '90px' }} />
                                                    <div className="text-center pt-3" style={{ height: '50px' }}>
                                                        <span className='fw-700' style={{ color: 'white' }}>Reisen</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xxl-4">
                                            <div className="row g-0 justify-content-center">
                                                <div className="col-auto my-auto">
                                                    <label className="containerFinPlus">
                                                        <input type="checkbox" name='membership' onChange={inputChecked} hidden />
                                                        <span className="checkmarkFinPlus"></span>
                                                    </label>
                                                </div>
                                                <div className="col-auto col-sm text-center">
                                                    <Checkbox3 style={{ width: '90px' }} />
                                                    <div className="text-center pt-3" style={{ height: '50px' }}>
                                                        <span className='fw-700' style={{ color: 'white' }}>Gute Zahnleistungen</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="pt-5">
                                        <div className="row g-3">
                                            <div className="col-6">
                                                <button className='px-4 px-sm-5 py-3 w-100 finanuWhiteBtn' onClick={() => { setstep(step - 1) }}>Zurück</button>
                                            </div>
                                            <div className="col-6">
                                                <button className='px-4 px-sm-5 py-3 w-100 finanuWhiteBtn' onClick={() => { setstep(step + 1) }}>Weiter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: (step === 3 && checkboxes === 0) ? 'block' : 'none' }}>
                                <div className='text-center'>
                                    <span style={{ color: '#fff' }}>Eine Mitgliedschaft macht für Sie aktuell nur wenig Sinn. Sie können jedoch sparen, indem Sie einen Versicherungsvergleich tätigen (Krankenkassenvergleich von finanu.ch) und bei der Krankenkasse ein günstigeres Angebot auswählen. Insgesamt sparen Sie dadurch private Kosten von mehreren hundert Franken</span>
                                </div>
                                <div className="pt-5 text-center">
                                    <Link to='/versicherungen/krankenkasse' className='px-4 px-sm-5 py-3 w-100 finanuWhiteBtn text-decoration-none'>
                                        Vergleich der Krankenkasse fürs Jahr 2023
                                    </Link>
                                </div>
                            </div>
                            <div style={{ display: (step === 3 && checkboxes > 0) ? 'block' : 'none' }}>
                                <div className='pb-5'>
                                    <span style={{ color: '#fff' }}>
                                        Eine Mitgliedschaft macht für Sie Sinn. Sie können von Leistungsbeiträgen an „all step 2 choosen fields“ profitieren. Insgesamt sparen Sie dadurch private Kosten von mehreren hundert Franken. Bitte Füllen Sie das Formular aus, damit wir Ihnen ein persönliches Angebot zustellen können
                                    </span>
                                </div>
                                <div className="row gx-3 gy-4">
                                    <div className="col-12 col-sm-6">
                                        <div>
                                            <div className='pb-2'>
                                                <span className='fw-700' style={{ color: 'white' }}>Name</span>
                                            </div>
                                            <div >
                                                <input onChange={(e) => { setName(e.target.value) }} className={`form-control plusInput p-3 ${(triedSubmit && name === '') ? 'submitErrorInputStyle' : ''} `} type="text" placeholder='Name' style={{ background: 'white' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div>
                                            <div className='pb-2'>
                                                <span className='fw-700' style={{ color: 'white' }}>Vorname</span>
                                            </div>
                                            <div >
                                                <input type="text" placeholder='Vorname' defaultValue={vorname} onChange={(e) => { setVorname(e.target.value) }} className={`form-control plusInput p-3 ${(triedSubmit && vorname === '') ? 'submitErrorInputStyle' : ''} `} style={{ background: 'white' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div>
                                            <div className='pb-2'>
                                                <span className='fw-700' style={{ color: 'white' }}>E-mail</span>
                                            </div>
                                            <div >
                                                <input onChange={(e) => { setEmail(e.target.value) }} className={`form-control plusInput p-3 ${(triedSubmit && email === '') ? 'submitErrorInputStyle' : ''} `} type="email" placeholder='E-mail' style={{ background: 'white' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div>
                                            <div className='pb-2'>
                                                <span className='fw-700' style={{ color: 'white' }}>Telefon</span>
                                            </div>
                                            <div >
                                                <input onChange={(e) => { setPhone(e.target.value) }} className={`form-control plusInput p-3 ${(triedSubmit && phone === '') ? 'submitErrorInputStyle' : ''} `} type="tel" placeholder='Telefon' style={{ background: 'white' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div >
                                            <div className='pb-2'>
                                                <span className='fw-700' style={{ color: 'white' }}>Strasse/Nr</span>
                                            </div>
                                            <div >
                                                <input onChange={(e) => { setStreet(e.target.value) }} className={`form-control plusInput p-3 ${(triedSubmit && street === '') ? 'submitErrorInputStyle' : ''} `} type="text" placeholder='Strasse/Nr' style={{ background: 'white' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div >
                                            <div className='pb-2'>
                                                <span className='fw-700' style={{ color: 'white' }}>Postleitzahl, Ort</span>
                                            </div>
                                            <div >
                                                <input onChange={(e) => { setOrt(e.target.value) }} className={`form-control plusInput p-3 ${(triedSubmit && ort === '') ? 'submitErrorInputStyle' : ''} `} type="text" placeholder='Postleitzahl, Ort' style={{ background: 'white' }} />
                                            </div>
                                        </div>
                                    </div>
                                    {successSubmit && (
                                        <div className="col-12 text-center">
                                            <span style={{color: '#fff', fontWeight: 500}}>Vielen Dank für die Übermittlung!</span>
                                        </div>
                                    )}
                                </div>
                                <div className="pt-4">
                                    <div className="row g-3">
                                        <div className="col-6 ms-auto">
                                            <button className='px-4 px-sm-5 py-3 w-100 finanuWhiteBtn' onClick={step3Submit}>Weiter</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 bodyCounterPadding mt-auto text-center order-2 order-md-3">
                        <div style={{ width: '100%', maxWidth: '850px' }} className="mx-auto">
                            <div className="px-0 px-md-4 px-lg-5 pb-5">
                                <div className=''>
                                    <span className='fs-1 fw-700 normalTextToWhite'>Wieso wir den Verein ins Leben gerufen haben.</span>
                                </div>

                            </div>
                            <div className="videobackground pe-2 pt-3">
                                <div className=''>
                                    <Vid1 className="img-fluid pt-2 videoinsecondsection" style={{ borderRadius: '20px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FirstSection