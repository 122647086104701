import React from 'react'
import { ReactComponent as Signature } from '../../assets/images/plus/signature.svg'
import { ReactComponent as SignatureDark } from '../../assets/images/plus/signaturedark.svg'

import Accordion from 'react-bootstrap/Accordion';
function OurStory(props) {

    return (
        <div className='container-xl px-4 px-xl-0'>
            <div className='text-start'>
                <div className="d-block d-md-none">
                    <div className='pb-3'>
                        <span className='plusTitle fs-2 fw-700 normalTextToWhite'>
                            Unsere Geschichte
                        </span>
                    </div>
                    <div className='pb-3'>
                        <span style={{textTransform: 'uppercase'}} className='fw-600 normalTextToWhite'>Erzählt von unserem Gründer</span>
                    </div>  
                </div>
                <div className="row gx-4 gy-5 gy-md-0 justify-content-between">
                    <div className="col-12 col-md-5 order-2 order-md-1">
                        <div className="d-none d-md-block col offset-lg-">
                            <div className='pb-2'>
                                <span className='plusTitle fs-2 fw-700 normalTextToWhite'>
                                    Unsere Geschichte
                                </span>
                            </div>
                            <div className='pb-5'>
                                <span style={{textTransform: 'uppercase'}}  className='fw-600 normalTextToWhite'>Erzählt von unserem Gründer</span>
                            </div>
                        </div>
                        <div>
                            <div className="row gx-0 text-start signatureRow">
                                <div className="col-6 col-sm-4  order-1 order-md-1">
                                    <div className='pb-5 signaturediv'>
                                        {props.darkMode
                                            ? <SignatureDark className="img-fluid signaturephoto" />
                                            : <Signature className="img-fluid signaturephoto" />
                                        }
                                    </div>
                                    <div className='pb-3 text-center text-md-start'>
                                        <span className='fw-600 normalTextToWhite fs-5'>Samir Menasria</span>
                                    </div>
                                    <div className='text-center text-md-start'>
                                        <span className='fw-600 normalTextToWhite'>Inhaber Swissma <br></br> GmbH</span>
                                    </div>
                                </div>

                                {/* <div className="col-6 col-sm-4 order-2 order-md-2">
                                    <div className='pb-5 signaturediv'>
                                        {props.darkMode
                                            ? <SignatureDark className="img-fluid signaturephoto mx-auto" />
                                            : <Signature className="img-fluid signaturephoto mx-auto" />
                                        }

                                    </div>
                                    <div className='pb-3'>
                                        <span className='fw-600 normalTextToWhite fs-5'>Samir Menasria</span>
                                    </div>
                                    <div>
                                        <span className='fw-600 normalTextToWhite'>CO-CEO</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="col order-1 order-md-2">

                        <div className='pb-3'>
                            <span className='normalTextToWhite fw-500 fw-md-700 fs-md-1 fs-5'>Wieso wir die Plattform finanu.ch ins Leben gerufen haben?</span>
                        </div>
                        <div className='normalTextToWhite fw-500 fs-5'>
                            <div className='pb-3'>
                                <span>
                                    Sicherlich nicht, weil wir Versicherungen lieben. Eigentlich ganz im Gegenteil!
                                </span>
                            </div>
                            <div className='pb-3'>
                                <span>
                                    Die Kurzfassung ist: Wir haben festgestellt, dass viele Versicherungen zu wenig aufzeigen, wie die Kunden eigentlich von den versprochenen Leistungen profitieren können.
                                </span>
                            </div>
                            <div className='pb-3'>
                                <span>
                                    Vor allem bei Langzeitkunden, welche ihre Verträge nicht regelmässig überprüfen, gehen so hunderte Franken pro Jahr verloren.
                                </span>
                            </div>
                            <div className='pb-3'>
                                <span>
                                    Aus diesem Grund haben wir beschlossen, die Dinge richtig zu stellen und einen Teil der daraus entstehenden Einnahmen in Form von Rückerstattungen an die Mitglieder zurückzugeben
                                </span>
                            </div>
                        </div>

                        {(props.screenWidth < 575.98) && (
                            <div className="pt-4">
                                <Accordion className='py-3' >
                                    <Accordion.Item eventKey="0" className='pt-3 accordionstyle' >
                                        <Accordion.Header className='faqstyle'>Lorem Ipsum lorem lorem</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className='pt-3 accordionstyle'>
                                        <Accordion.Header className='faqstyle'>Lorem Ipsum lorem lorem</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" className='pt-3 accordionstyle'>
                                        <Accordion.Header className='faqstyle'>Lorem Ipsum lorem lorem</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3 " className='pt-3 accordionstyle'>
                                        <Accordion.Header className='faqstyle'>Lorem Ipsum lorem lorem</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurStory