import { useEffect } from "react";
import { useLocation } from "react-router";
const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, [location]);
    return <>{props.children}</>
};
export default ScrollToTop;