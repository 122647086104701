import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'
import de from 'react-phone-number-input/locale/de'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

function FourteenthStep(props) {
  const [telefonnumer, setTelefonnumer] = useState('');
  const [firstDate, setfirstDate] = useState('')

  return (
    <div className="text-start px-0 px-sm-4 px-md-5 pb-5 pt-4 col-12 col-sm-11 col-md-12 col-lg-9 col-xl-8 mx-auto pt-md-5 mx-auto">
      <div className='pb-4'>
        <div className='pb-2'>
          <span className='fw-600'>Vorname und Nachname / Firmenname</span>
        </div>
        <div>
          <input className='krankenInputStyle' type="text" name="" placeholder='Vorname und Nachname / Firmenname' id="" />
        </div>
      </div>
      <div className='pb-4'>
        <div className="row g-4">
          <div className="col-12 col-md-6">
            <div className='pb-2'>
              <span className='fw-600'>Postleitzahl</span>
            </div>
            <div className="">
              <input disabled className='krankenInputStyle' defaultValue={props.plz} type="text" name="" placeholder='PLZ' id="" />
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='pb-2'>
              <span className='fw-600'>Ort</span>
            </div>
            <div className="">
              <input disabled className='krankenInputStyle' defaultValue={props.ort} type="text" name="" placeholder='Ort' id="" />
            </div>
          </div>
        </div>
      </div>
      <div className='pb-4'>
        <div className='pb-2'>
          <span className='fw-600'>Strasse</span>
        </div>
        <div>
          <input className='krankenInputStyle' type="text" name="" placeholder='Strasse' id="" />
        </div>
      </div>
      <div className='pb-4'>
        <div className="row g-4">
          <div className="col-12 col-md-6">
            <div className='pb-2'>
              <span className='fw-600'>Geburtsdatum</span>
            </div>
            <div className="">
              <DatePicker
                className={`form-control dateStyleIOS`}
                onChange={e => setfirstDate(e)}
                selected={firstDate}
                dateFormat={'dd/MM/yyyy'}
                placeholderText='dd/mm/yyyy'
              />
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='pb-2'>
              <span className='fw-600'>Telefonnummer</span>
            </div>
            <div className="">
              <PhoneInput
                className='krankenInputStyle 360PhoneInput'
                defaultCountry="CH"
                international
                labels={de}
                withCountryCallingCode
                countrySelectProps={{ unicodeFlags: true }}
                value={telefonnumer}
                onChange={setTelefonnumer} />
            </div>
          </div>
        </div>
      </div>
      <div className='pb-4'>
        <div className='pb-2'>
          <span className='fw-600'>E-mail</span>
        </div>
        <div>
          <input className='krankenInputStyle' type="text" name="" placeholder='E-mail' id="" />
        </div>
      </div>
      <div className='text-center'>
        <div className="col-12 col-md-4 col-lg-4 mx-auto">
          <button className='step3RechtssBtn w-100'>Einreichen</button>
        </div>
      </div>
    </div>
  )
}

export default FourteenthStep