import React from 'react'
import { ReactComponent as MainImg } from '../../assets/images/auto/mainImg.svg'
import { ReactComponent as MainImgDark } from '../../assets/images/auto/mainImgDark.svg'

function FirstSectionAuto(props) {
    return (
        <>
            <div className="container-xl px-4 px-xl-0 bodyCounterPadding">
                <div className="row justify-content-between gy-5 gy-md-0 gx-5">
                    <div className="col-12 col-md-6 col-lg-6 my-md-auto">
                        <div className='pb-3 text-start'>
                            <span className='fw-700 fs-1 firstBlueTitle'>Vergleichen Sie die Autoversicherung und finden Sie das beste Angebot!</span>
                        </div>
                        <div className='text-start pb-4'>
                            <span className='normalTextToWhite fs-5'>
                                Ihr solltet eure Autoversicherung vergleichen, um das beste Angebot für eure Bedürfnisse und euer Fahrverhalten zu finden. Durch einen Vergleich könnt ihr sicherstellen, dass ihr nicht zu viel für eure Autoversicherung bezahlt und dass ihr Zusatzleistungen und Optionen entdeckt, die für euch von Nutzen sein können. Auf diese Weise könnt ihr sicher sein, dass ihr eure Autoversicherung optimal nutzt.
                            </span>
                        </div>
                        <div className='text-start'>
                            <button onClick={props.toSteps} className='hausratBtn py-2'>
                                Jetzt Offere anfragen
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 my-md-auto">

                        <div className="">
                            <div>
                                {props.darkMode

                                    ? <MainImgDark className="img-fluid" />
                                    : <MainImg className="img-fluid" />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FirstSectionAuto