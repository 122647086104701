import React from 'react'

function JoinUs() {
    return (
        <div className='container-xl  px-4 px-xl-0 text-start'>
            <div className="row gx-5 justify-content-center">
                <div className="col-auto col-md col-xl-7 my-auto">
                    <div className='pb-3 pb-sm-0'>
                        <span className='fs-1 normalTextToWhite fw-700'>
                        Werde jetzt Mitglied
                        </span> 
                    </div>
                    
                </div>
                <div className="col-auto col-md-auto my-auto">
                    <div className=''>
                        <button className='plusBtn fw-700 w-100 px-3 px-md-5'>Mitgliedschaften</button>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default JoinUs