import React from 'react'
import NumberFormat from 'react-number-format';

function Results(props) {
    const income = props.thirdStepInputs[0] + props.thirdStepInputs[1]

    const fourthStep = props.fourthStepInputs[0] + props.fourthStepInputs[1]

    const fifthStep = props.fifthStepInputs[0] + props.fifthStepInputs[1]

    const sixthStep = props.sixthStepInputs[0] + props.sixthStepInputs[1] + props.sixthStepInputs[2] + props.sixthStepInputs[3] + props.sixthStepInputs[4] + props.sixthStepInputs[5] + props.sixthStepInputs[6]

    const seventhStep = props.seventhStepInputs[0] + props.seventhStepInputs[1] + props.seventhStepInputs[2] + props.seventhStepInputs[3] + props.seventhStepInputs[4]

    let eight = props.eightthStepInputs[0] + props.eightthStepInputs[1] + props.eightthStepInputs[2] + props.eightthStepInputs[3] + props.eightthStepInputs[4]

    let nine = props.ninthStepInputs[0] + props.ninthStepInputs[1] + props.ninthStepInputs[2] + props.ninthStepInputs[3] + props.ninthStepInputs[4] + props.ninthStepInputs[5] + props.ninthStepInputs[6]

    let ten = props.tenthStepInputs[0] + props.tenthStepInputs[1] + props.tenthStepInputs[2] + props.tenthStepInputs[3]

    let eleven = props.eleventhStepInputs[0] + props.eleventhStepInputs[1] + props.eleventhStepInputs[2]

    const twelve = props.twelvthStepInputs[0] + props.twelvthStepInputs[1]

    const thirdteen = props.thirdteenthStepInputs[0] + props.thirdteenthStepInputs[1]

    const expenses = fourthStep + fifthStep + sixthStep + seventhStep + seventhStep + eight + nine + ten + eleven + twelve + thirdteen

    return (
        <>
            <div className="blueBorderDiv p-3 p-sm-4 text-start" style={{ borderColor: '#fff' }}>
                <div className='pb-4'>
                    <span className="fw-700 fs-3">Wie viel bleibt Ihnen übrig?</span>
                </div>
                <div className='pb-4'>
                    <div className="row g-0">
                        <div className="col">
                            <div>
                                <span className='fw-500'>Einnahmen pro Monat/Jahr</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div>
                                <span className='fw-500'> <NumberFormat value={income} displayType={'text'} thousandSeparator={true} /> CHF</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-4'>
                    <div className="row g-0">
                        <div className="col">
                            <div>
                                <span className='fw-500'>Ausgaben pro Monat</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div>
                                <span className='fw-500'> <NumberFormat value={expenses} displayType={'text'} thousandSeparator={true} /> CHF </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-5'>
                    <div className="row g-0">
                        <div className="col">
                            <div>
                                <span className='fw-500'>Unterschied</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div>
                                <span className='fw-500'> <NumberFormat value={income - expenses} displayType={'text'} thousandSeparator={true} /> CHF </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-4'>
                    <span className="fw-700 fs-3">Empfehlungen für Ihre Budgetplanung</span>
                </div>
                <div className='pb-4'>
                    <div className="row g-0">
                        <div className="col">
                            <div>
                                <span className='fw-500'>Krankenkasse inkl. Zusatzversicherung</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div>
                                <span className='fw-500'> <NumberFormat value={100} displayType={'text'} thousandSeparator={true} /> CHF</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-4'>
                    <div className="row g-0">
                        <div className="col">
                            <div>
                                <span className='fw-500'>Versicherungen</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div>
                                <span className='fw-500'> <NumberFormat value={100} displayType={'text'} thousandSeparator={true} /> CHF</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-4'>
                    <div className="row g-0">
                        <div className="col">
                            <div>
                                <span className='fw-500'>3. Säule (3a)</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div>
                                <span className='fw-500'> <NumberFormat value={100} displayType={'text'} thousandSeparator={true} /> CHF</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-4'>
                    <div className="row g-0">
                        <div className="col">
                            <div>
                                <span className='fw-500'>Telefon, Handy</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div>
                                <span className='fw-500'> <NumberFormat value={100} displayType={'text'} thousandSeparator={true} /> CHF</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-4'>
                    <div className="row g-0">
                        <div className="col">
                            <div>
                                <span className='fw-500'>Internet, TV, Radio</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div>
                                <span className='fw-500'> <NumberFormat value={100} displayType={'text'} thousandSeparator={true} /> CHF</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-4'>
                    <div className="row g-0">
                        <div className="col">
                            <div>
                                <span className='fw-500'>Wohnen</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div>
                                <span className='fw-500'> <NumberFormat value={100} displayType={'text'} thousandSeparator={true} /> CHF</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mx-auto">
                    <button className='stepsNextBtn' onClick={props.toNext}>Weiter</button>
                </div>
            </div>
        </>
    )
}

export default Results