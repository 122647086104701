import React from 'react'
// import { ReactComponent as Ilu } from '../../assets/images/plus/vorarIlu.svg'

import { ReactComponent as Ilu } from '../../assets/images/plus/Qifti-01.svg'
import { ReactComponent as IluDark } from '../../assets/images/plus/Qifti-02.svg'

function Vorar(props) {
    return (
        <div className='container-xl px-4 px-xl-0 text-start'>
            <div className='pb-5 text-center'>
                <span className='fs-1 fw-700 triaryColor '>Voraussetzungen für den Erhalt der freiwilligen Ergänzungsbeiträge</span>
            </div>
            <div>
                <div className="row g-4 paddingoftherowofvorar">
                    <div className="col-lg-4 col-6">
                        {props.darkMode

                            ? <IluDark className="img-fluid" />
                            : <Ilu className="img-fluid" />
                        }
                    </div>
                    <div className="col fs-5 fw-600 my-auto normalTextToWhite">
                        {/* <div className="pb-3">
                            <span className='fs-5 fw-700'>
                                Lorem Ipsum
                            </span>
                        </div> */}
                        <div className="pb-3">
                            <span className='fs-6 fw-400'>
                                Die freiwilligen Ergänzungsbeiträge von Finanu+ sind nicht mit den Versicherungsleistungen des Krankenkassenpartners zu verwechseln.
                            </span>
                        </div>
                        <div className="pb-3">
                            <span className='fs-6 fw-400'>
                                Finanu+ erbringt diese freiwillig im Sinne des Vereinszwecks und basierend auf den gültigen Statuten.
                            </span>
                        </div>
                        <div className="pb-3">
                            <span className='fs-6 fw-400'>
                                Alle freiwilligen Ergänzungsbeiträge werden nur bei bestehender Mitgliedschaft ausbezahlt. Massgebend ist jeweils der Zeitpunkt der Behandlung oder. 
                            </span>
                        </div>
                        <div>
                            <span className='fs-6 fw-400'>Es gilt eine Verjährungsfrist von einem Jahr ab Datum der Behandlung oder ab Ereignisdatum. Nicht bezahlte Mitgliederbeiträge führen zur nicht Bezahlung der Ergänzungsbeiträge!</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-12 my-auto pt-4 pt-lg-0" style={{ textAlign: '-webkit-center' }}>
                        <div className='cardofthelastcomponent d-flex'>
                            <div className='px-4 '>
                                <p className='text-center fw-400 pt-2'>Finanu + weiterempfehlen</p>
                                <p className='text-center fw-700 pt-2'>Für jede erfolgreiche Empfehlung erhalten Sie einen Gutschein im Wert von 50.- CHF.</p>
                                <div className='text-center pt-1'>
                                    <button className='buttonmeineempfehlung py-1 px-3'>
                                        Meine Empfehlung
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    <button className='lastcomponentbutton py-2 border-0 ' style={{ borderRadius: '10px' }}>Jetzt Mitgliedschaft anfragen.</button>
                </div>
            </div>
        </div>
    )
}

export default Vorar