import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import de from 'react-phone-number-input/locale/de'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

function ThirdStep(props) {
    const [vorname, setvorname] = useState('')
    const [nachname, setnachname] = useState('')
    const [strasse, setstrasse] = useState('')
    const [plzOrt, setplzOrt] = useState('')
    const [telefonnumer, setTelefonnumer] = useState('');
    const [email, setemail] = useState('')
    const [firstDate, setfirstDate] = useState('')
    const [nationalitat, setnationalitat] = useState('')
    const [triedSubmit, settriedSubmit] = useState(false)

    const submit = () => {
        settriedSubmit(true)
        if (triedSubmit !== '' && nationalitat !== '' && firstDate !== '' && email !== '' && telefonnumer !== '' && plzOrt !== '' &&
        strasse !== '' && nachname !== '' && vorname !== '') {
            props.setstep(props.step + 1)
        }
    }
    return (
        <div className='autoThirdStep'>
            <div className="pb-3">
                <span className='fw-700  fs-5'>
                    Personalien
                </span>
            </div>
            <div className='pb-4'>
                <span className='firstBlueTitle fw-700 fs-4'>Versicherungsnehmer</span>
            </div>
            <div className='pb-4'>
                <div className="row g-4 justify-content-center">
                    <div className="col-12 col-sm-auto">
                        <input type="text" onChange={(e) => {setvorname(e.target.value)}} className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && vorname === '') ? 'submitErrorInputStyle' : ''}`} placeholder='Vorname *' />
                    </div>
                    <div className="col-12 col-sm-auto">
                        <input type="text" onChange={(e) => {setnachname(e.target.value)}} className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && nachname === '') ? 'submitErrorInputStyle' : ''}`} placeholder='Nachname *' />
                    </div>
                </div>
            </div>
            <div className="pb-4">
                <div className="row g-4 justify-content-center">
                    <div className="col-12 col-sm-auto">
                        <input type="text" onChange={(e) => {setstrasse(e.target.value)}} className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && strasse === '') ? 'submitErrorInputStyle' : ''}`} placeholder='Strasse & Nr. *' />
                    </div>
                    <div className="col-12 col-sm-auto">
                        <input type="text" onChange={(e) => {setplzOrt(e.target.value)}} className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && plzOrt === '') ? 'submitErrorInputStyle' : ''}`} placeholder='PLZ / Ort *' />
                    </div>
                </div>
            </div>
            <div className="pb-4">
                <div className="row g-4 justify-content-center">
                    <div className="col-12 col-sm-auto">
                        <PhoneInput
                            className={`krankenInputStyle krankenInputStepStyle p-3 autoCheckbox ${(triedSubmit && telefonnumer === '') ? 'submitErrorInputStyle' : ''}`}
                            defaultCountry="CH"
                            international
                            labels={de}
                            withCountryCallingCode
                            countrySelectProps={{ unicodeFlags: true }}
                            value={telefonnumer}
                            onChange={setTelefonnumer} />
                    </div>
                    <div className="col-12 col-sm-auto">
                        <input type="text" onChange={(e) => {setemail(e.target.value)}} className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && email === '') ? 'submitErrorInputStyle' : ''}`} placeholder='E-Mail-Adresse *' />
                    </div>
                </div>
            </div>
            <div className="pb-5">
                <div className="row g-4 justify-content-center">
                    <div className="col-12 col-sm-auto">
                        <DatePicker
                            className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && firstDate === '') ? 'submitErrorInputStyle' : ''}`}
                            onChange={e => setfirstDate(e)}
                            selected={firstDate}
                            dateFormat={'dd/MM/yyyy'}
                            placeholderText='Geburtsdatum *'
                        />
                    </div>
                    <div className="col-12 col-sm-auto">
                        <input type="text" onChange={(e) => {setnationalitat(e.target.value)}} className={`krankenInputStyle krankenInputStepStyle p-2 form-control p-3 autoCheckbox ${(triedSubmit && nationalitat === '') ? 'submitErrorInputStyle' : ''}`} placeholder='Nationalität *' />
                    </div>
                </div>
            </div>
            <div className="row gx-4 gy-0 justify-content-center">
                <div className="col-6 col-sm-auto">
                    <div>
                        <button className='autoBtnStyle' onClick={() => { props.setstep(props.step - 1) }}>
                            Zurück
                        </button>
                    </div>
                </div>
                <div className="col-6 col-sm-auto">
                    <div>
                        <button type='button' className='autoBtnStyle' onClick={submit}>
                            Berechnung
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ThirdStep