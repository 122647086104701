import React from 'react'

import Newsletter from '../../assets/images/plus/Newsletter.svg'
import NewsletterDark from '../../assets/images/plus/NewsletterDark.svg'

import Personliche from '../../assets/images/plus/PersonlicheAnsprechperson.svg'
import PersonlicheDark from '../../assets/images/plus/PersonlicheAnsprechpersonDark.svg'

import Ruckvergutungen from '../../assets/images/plus/Ruckvergutungen.svg'
import RuckvergutungenDark from '../../assets/images/plus/RuckvergutungenDark.svg'

import Ange from '../../assets/images/plus/AngeboteundVergunstigungen.svg'
import AngeDark from '../../assets/images/plus/AngeboteundVergunstigungenDark.svg'

import Wettbewerbe from '../../assets/images/plus/Wettbewerbe.svg'
import WettbewerbeDark from '../../assets/images/plus/WettbewerbeDark.svg'

function CoverYouNeed(props) {
    return (
        <div className='container-xl px-4 px-xl-0'>
            <div className='pb-4'>
                <span className='fs-1 fw-700 normalTextToWhite'>Deshalb lieben uns unsere Mitglieder</span>
            </div>
            <div className="row g-4 justify-content-center">
                <div className="col-12 col-sm-6 col-lg-4">
                    <div className="plusCoverDiv px-4 py-5">
                        <div className="pb-3">
                            {props.darkMode
                                ? <img src={NewsletterDark} width={120} height={120} alt='' />
                                : <img src={Newsletter} width={120} height={120} alt='' />

                            }
                        </div>
                        <div className='pb-3'>
                            <span className='fs-5 fw-700 normalTextToWhite'>Newsletter</span>
                        </div>
                        <div className="pb-3">
                            <span className='fw-500 normalTextToWhite'>
                                Unsere Kunden erhalten quartalsweise einen Newsletter mit den neusten Änderungen und neuen Angeboten der verschiedenen Versicherungen. In unserem Blog geben wir Tipps und Tricks  zur Leistungsverrechnung an die Gesellschaften. Zusätzlich erinnern wir euch an die verschiedenen Fristen der Grund- und Zusatzversicherungen.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                    <div className="plusCoverDiv px-4 py-5">
                        <div className="pb-3">
                            {props.darkMode
                                ? <img src={PersonlicheDark} width={120} height={120} alt='' />
                                : <img src={Personliche} width={120} height={120} alt='' />

                            }
                        </div>
                        <div className='pb-3'>
                            <span className='fs-5 fw-700 normalTextToWhite'>Persönliche Ansprechperson</span>
                        </div>
                        <div className="pb-3">
                            <span className='fw-500 normalTextToWhite'>
                                Sie erhalten digitale Unterstützung durch unseren Chatbot und eine dedizierte Ansprechperson bei komplexeren Angelegenheiten.
                            </span>

                        </div>

                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                    <div className="plusCoverDiv px-4 py-5">
                        <div className="pb-3">
                            {props.darkMode
                                ? <img src={RuckvergutungenDark} width={120} height={120} alt='' />
                                : <img src={Ruckvergutungen} width={120} height={120} alt='' />

                            }
                        </div>
                        <div className='pb-3'>
                            <span className='fs-5 fw-700 normalTextToWhite'>Rückvergütungen</span>
                        </div>
                        <div className="pb-3">
                            <span className='fw-500 normalTextToWhite'>
                                Wir unterstützen die Gesundheit und Vorsorge unserer Kunden. Je nach gewähltem Paket erhalten die Mitglieder basierend auf den Einnahmen vom Verein eine Rückvergütung auf den Beiträgen an Sportvereine, Zahnarztkosten oder
                            </span>
                        </div>
                    </div>

                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                    <div className="plusCoverDiv px-4 py-5">
                        <div className="pb-3">
                            {props.darkMode
                                ? <img src={AngeDark} width={120} height={120} alt='' />
                                : <img src={Ange} width={120} height={120} alt='' />

                            }
                        </div>
                        <div className='pb-3'>
                            <span className='fs-5 fw-700 normalTextToWhite'>Angebote und Vergünstigungen</span>
                        </div>
                        <div className="pb-3">
                            <span className='fw-500 normalTextToWhite'>
                                Als Verein handeln wir diverse Vergünstigungen im Gesundheitsbereich für unsere Kunden aus.
                            </span>

                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                    <div className="plusCoverDiv px-4 py-5">
                        <div className="pb-3">
                            {props.darkMode
                                ? <img src={WettbewerbeDark} width={120} height={120} alt='' />
                                : <img src={Wettbewerbe} width={120} height={120} alt='' />

                            }
                        </div>
                        <div className='pb-3'>
                            <span className='fs-5 fw-700 normalTextToWhite'>Wettbewerbe</span>
                        </div>
                        <div className="pb-3">
                            <span className='fw-500 normalTextToWhite'>
                                Unter unseren Mitgliedern verlosen wir monatlich gemeinsam mit unseren Partnern diverse Gutscheine für Events, Ausflüge und Übernachtungen.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoverYouNeed