import React from 'react'
import { useState, useEffect } from 'react'
import '../assets/css/uberUns.css'

import { ReactComponent as Idea } from '../assets/images/UberUns.svg'
import { ReactComponent as IdeaMobile } from '../assets/images/UberUns-Mobile.svg'
import { ReactComponent as IdeaDark } from '../assets/images/UberUns-Dark.svg'
import { ReactComponent as IdeaMobileDark } from '../assets/images/UberUns-Dark-Mobile.svg'

// import Muscle from '../assets/images/uberUns/muscle.svg'
import { ReactComponent as Talk } from '../assets/images/uberUns/uberUnsTalk.svg'
import { ReactComponent as Sig } from '../assets/images/plus/signature.svg'
import { ReactComponent as SignatureDark } from '../assets/images/plus/signaturedark.svg'




function UberUns(props) {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    return (
        <>
            <div className="wrapDiv container-xl px-4 px-xl-0 mx-auto">
                <div className='pb-5'>
                    <div className="row g-0 justify-content-center">

                        <div className="col-12">
                            {(screenWidth > 575.98) && (

                                <>
                                    {props.darkMode

                                        ? <IdeaDark style={{ maxWidth: 'max-content' }} />
                                        : <Idea style={{ maxWidth: 'max-content' }} />
                                    }
                                </>
                            )}
                            {(screenWidth < 575.98) && (
                                <>
                                    {props.darkMode
                                        ? <IdeaMobileDark style={{ width: '100%' }} />
                                        : <IdeaMobile style={{ width: '100%' }} />
                                    }
                                </>

                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-xl px-4 px-xl-0 bodyCounterPadding'>
                <div className="row gx-4 justify-content-between ">
                    <div className="col-12 col-md-5 col-xl-4 text-start">
                        <div className='pb-4' >
                            <span className='fw-700 firstBlueTitle fs-1'>Unsere Aufgabe</span>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-7 col-xl-7 text-start">

                        <div className='pb-3'>
                            <span className='fw-500 fs-5 normalTextToWhite'>Finanu hilft Ihnen als unabhängige Vergleichsplattform dabei sich in der unübersichtlichen Landschaft der Versicherungen und Finanzdienstleister zurechtzufinden. Mit wenigen Klicks können Sie verschiedene Angebote vergleichen und unverbindliche Offerten anfordern.
                            </span>
                        </div>
                        <div>
                            <span className='fw-500 fs-5 normalTextToWhite'>
                                Des Weiteren haben Sie die Option weitere Fragen via Chatbot, Live Chat oder bei einer persönlichen Beratung zu klären. Finanu unterstützt und begleitet Sie kostenlos bis zum Abschluss.
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="bodyCounterPadding">
                <div className="uberUnsBg">
                    <div className="container-xl px-4 px-xl-0">

                        <div className="row gx-4 justify-content-between ">
                            <div className="col-12 col-md-5 col-xl-4 text-start">
                                <div className='pb-4' >
                                    <span className='fw-700 firstBlueTitle fs-1'>Wieso wir finanu.ch ins Leben gerufen haben</span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-7 col-xl-7 text-start">

                                <div className=''>
                                    <span className='fw-500 fs-5 normalTextToWhite'>
                                        Unser Unternehmen wurde gegründet, um dem Schweizer Krankenkassensystem und seinen hohen Kosten entgegenzuwirken. Wir nutzen Technologie, Automatisierung und Künstliche Intelligenz, um den Aufwand und die damit verbundenen hohen Kosten für alle Parteien zu reduzieren. Durch unsere Lösungen bieten wir Ihnen eine kostengünstige und effiziente Lösung um die Versicherungen zu vergleichen. Profitieren Sie von unserem Know-how und unseren innovativen Lösungen.
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-xl px-4 px-xl-0">
                <div className="row gx-4 gy-5 gy-md-0 justify-content-between bodyCounterPadding">
                    <div className="col-12 col-md-5 col-xl-4 text-start my-md-auto">
                        <Talk />
                    </div>
                    <div className="col-12 col-md-6 col-lg-7 col-xl-7 text-start my-md-auto">
                        <div className='pb-3' style={{ wordBreak: 'break-all' }}>
                            <span className='fw-700 firstBlueTitle fs-1'>Unternehmensphilosophie</span>
                        </div>
                        <div className='pb-3'>
                            <span className='fw-500 fs-5 normalTextToWhite'>
                                Unsere Unternehmensphilosophie basiert auf den Grundsätzen: Unabhängigkeit, Objektivität, Kompetenz, Leidenschaft und Vertraulichkeit. Als unabhängiger Finanzdienstleister beraten wir unsere Kunden individuell. Wir bieten Ihnen nachhaltige Konzepte, ob als Privatperson oder KMU. Wir sorgen für Ihre finanzielle Absicherung unter dem Aspekt der grösst möglichen Freiheit.

                            </span>
                        </div>
                        <div>
                            <span className='fw-500 fs-5 normalTextToWhite'>
                                Unsere Bewertungen erfolgen unabhängig von Dritten, unsere Beratung berücksichtigt Chancen und Risiken. Wir engagieren uns für unsere Kunden voll Leidenschaft und beraten kompetent und zuverlässig.
                            </span>
                        </div>
                    </div>
                </div>
                

                <div className='px-0 px-md-4 px-lg-5 bodyCounterPadding'>
                    <div className="row g-0">
                        <div className="col-6 col-md-4 ">

                        </div>
                        <div className="col col-md-4">
                            <div className="uberBlueBg ps-1 py-3 ps-md-0">
                                <span className='fw-600'>Finanu</span>
                            </div>
                        </div>
                        <div className="col col-md-4">
                            <div className="uberBlueBg2 pe-1 py-3 pe-md-0">
                                <span className='fw-600'>Klassische</span>
                            </div>
                        </div>
                    </div>
                    <div className="lightBlueBgUber" id='Unsere'>
                        <div className="row g-0">
                            <div className="col-6 col-md-4 text-start greyBorderRightUber greyBorderBottomUber ">
                                <div className=''>
                                    <span className='fw-600'>Gesamtberatung</span>
                                </div>
                            </div>
                            <div className="col col-md-4 greyBorderRightUber greyBorderBottomUber">
                                <svg viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32 2L11.375 25L2 14.5455" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col col-md-4 greyBorderBottomUber">
                                <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 2L2 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 2L27 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col-6 col-md-4 text-start greyBorderRightUber greyBorderBottomUber">
                                <div>
                                    <span className='fw-600'>Ordnungssystem</span>
                                </div>
                            </div>
                            <div className="col col-md-4 greyBorderRightUber greyBorderBottomUber">
                                <svg viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32 2L11.375 25L2 14.5455" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col col-md-4 greyBorderBottomUber">
                                <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 2L2 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 2L27 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col-6 col-md-4 text-start greyBorderRightUber greyBorderBottomUber">
                                <div>
                                    <span className='fw-600'>Budget App</span>
                                </div>
                            </div>
                            <div className="col col-md-4 greyBorderRightUber greyBorderBottomUber">
                                <svg viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32 2L11.375 25L2 14.5455" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col col-md-4 greyBorderBottomUber">
                                <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 2L2 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 2L27 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col-6 col-md-4 text-start greyBorderRightUber greyBorderBottomUber">
                                <div>
                                    <span className='fw-600'>Jährliche Optimierung</span>
                                </div>
                            </div>
                            <div className="col col-md-4 greyBorderRightUber greyBorderBottomUber">
                                <svg viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32 2L11.375 25L2 14.5455" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col col-md-4 greyBorderBottomUber">
                                <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 2L2 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 2L27 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col-6 col-md-4 text-start greyBorderRightUber greyBorderBottomUber">
                                <div>
                                    <span className='fw-600'>Unabhangig</span>
                                </div>
                            </div>
                            <div className="col col-md-4 greyBorderRightUber greyBorderBottomUber">
                                <svg viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32 2L11.375 25L2 14.5455" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col col-md-4 greyBorderBottomUber">
                                <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 2L2 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 2L27 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col-6 col-md-4 text-start greyBorderRightUber greyBorderBottomUber">
                                <div>
                                    <span className='fw-600'>Exklusivvertreter</span>
                                </div>
                            </div>
                            <div className="col col-md-4 greyBorderRightUber greyBorderBottomUber">
                                <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 2L2 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 2L27 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </div>
                            <div className="col col-md-4 greyBorderBottomUber">
                                <svg viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32 2L11.375 25L2 14.5455" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col-6 col-md-4 text-start greyBorderRightUber">
                                <div>
                                    <span className='fw-600'>Flexibilitat</span>
                                </div>
                            </div>
                            <div className="col col-md-4 greyBorderRightUber">
                                <svg viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32 2L11.375 25L2 14.5455" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="col col-md-4">
                                <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 2L2 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 2L27 27" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gx-4 gy-5 gy-md-0 justify-content-between bodyCounterPadding">
                    <div className="col-12 col-md-5 text-start my-md-auto" >
                        <div className='pb-3'>
                            <span className='fw-700 firstBlueTitle fs-1'>Unser Versprechen</span>
                        </div>

                        <div className='pb-3'>
                            <span className='normalTextToWhite'>

                                Unser Versicherungsvergleichsportal nutzt künstliche Intelligenz, um Ihnen individuelle Versicherungslösungen zu möglichst günstigen Preisen anzubieten.
                            </span>
                        </div>
                        <div>
                            <span className='normalTextToWhite'>
                                Dabei berücksichtigen wir Ihre persönlichen Bedürfnisse und Wünsche, um Ihnen das bestmögliche Angebot zu unterbreiten. Verlassen Sie sich auf unsere Expertise und erhalten Sie die passende Versicherung zum optimalen Preis-Leistungs-Verhältnis.
                            </span>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 col-xl-5 col-xxl-4 text-start my-md-auto">
                        <div className="row g-0 justify-content-between">
                            <div className="col-6 col-md-auto">
                                <div className='pb-3 text-center'>
                                    {!props.darkMode

                                        ? < Sig className="uberSignature" />
                                        : < SignatureDark className="uberSignature" />
                                    }

                                </div>
                                <div className='text-center pb-3'>
                                    <span className='fw-500 fs-5 normalTextToWhite'>Samir <br></br> Menasria</span>
                                </div>
                                <div className='text-center '>
                                    <span className='normalTextToWhite fw-500'>CO-CEO</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-auto">
                                <div className='pb-3 text-center'>
                                    {!props.darkMode

                                        ? < Sig className="uberSignature" />
                                        : < SignatureDark className="uberSignature" />
                                    }

                                </div>
                                <div className='text-center pb-3'>
                                    <span className='fw-500 fs-5 normalTextToWhite'>Samir <br></br> Menasria</span>
                                </div>
                                <div className='text-center '>
                                    <span className='normalTextToWhite fw-500'>CO-CEO</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default UberUns